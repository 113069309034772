import langText from "../../lang/i18n";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { GetAccountAccessUrl, GetAccountsUrl } from "../../constants/endpoints";
import AccountAccess from "./AccountAccess";
import LoadingSpinner from "../../components/LoadingIndicator";

const AccessesAndRoles = () => {
  const { UserToken, UserData } = useAppSelector((state) => state.user);

  const [accountData, setAccountData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getAccounts();
  }, []);

  function getAccounts() {
    setIsLoading(true);
    fetch(GetAccountsUrl(UserData?.id), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setAccountData(data);
      });
  }

  const updateAccountAccess = (
    account: any,
    accessType: string,
    actionType: string,
    customerMobile: string
  ) => {
    fetch(GetAccountAccessUrl(UserData?.id, account.id.toString()), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        account: account.id,
        mobile: customerMobile, //'+355456789',
        accessRightType: accessType,
        action: actionType,
      }),
    })
      .then((response) => {
        if (response.ok) getAccounts();
        return response.json();
      })
      .then((data) => {})
      .catch((err: any) => {});
  };

  return (
    <div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 600,
          marginBottom: 20,
        }}
      >
        {langText("add_access")}
      </div>

      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        accountData.map((account: any) => {
          return (
            <AccountAccess
              account={account}
              updateAccountAccess={updateAccountAccess}
            />
          );
        })
      )}
    </div>
  );
};

export default AccessesAndRoles;
