import {
  unwantedOperators,
  useUtilityService,
} from "../../hooks/useUtilityService";
import langText from "../../lang/i18n";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const RegisterBillModal = ({
  modalVisible,
  onBackdropPress,
  provider,
  service,
  selected,
  setShouldReload = (bool: any) => {},
  selectContract = (contract: any) => {},
  operatorId,
  filter = () => true,
}: any) => {
  const { Theme, SelectedColor } = useSelector((state: any) => state.theme);
  const { contracts } = useAppSelector((state) => state.general);

  const [userInputs, setUserInputs] = useState(
    new Array(service.fields.length)
  );

  const modal = useRef(null);
  const { loading, saveContract, deleteContract, errorMsg } = useUtilityService(
    operatorId,
    service,
    modal as any
  );

  useEffect(() => {
    setUserInputs(new Array(service.fields.length));
  }, []);

  return (
    <Modal
      title={
        !langText("bill_id_desc_" + operatorId).startsWith("[missing")
          ? langText("bill_id_desc_" + operatorId)
          : langText("bill_id_desc")
      }
      isVisible={modalVisible}
      setIsVisible={() => {
        onBackdropPress();
      }}
    >
      <div style={{ height: "300px", padding: "1em" }}>
        {contracts?.filter(filter).map((contract: any, index: any) => {
          let thisIsSelected = true;

          let keysArray = Object.keys(selected ?? {});
          if (keysArray.length > 0)
            keysArray.forEach((key) => {
              if (contract[key] !== selected[key]) thisIsSelected = false;
            });
          else thisIsSelected = false;

          return (
            <div
              key={contract.id}
              onClick={() => {
                selectContract(contract);
                setTimeout(() => onBackdropPress(), 200);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomColor: Theme.textColor,
                color: thisIsSelected ? "white" : Theme.textColor,
                backgroundColor: thisIsSelected
                  ? SelectedColor
                  : Theme.backgroundColor,
                borderBottomWidth: 1,
                alignItems: "center",
                padding: "5px 20px",
                borderRadius: 10,
              }}
            >
              <div>
                {!unwantedOperators.includes(operatorId) ? (
                  typeof contract["vlera"] == "object" ? (
                    Object.keys(contract["vlera"]).map((key2, index2) => {
                      return (
                        <div
                          key={key2}
                          style={{
                            fontSize: 18,
                          }}
                        >
                          {langText(key2) + ": " + contract["vlera"][key2]}
                        </div>
                      );
                    })
                  ) : (
                    <div key={index} style={{ fontSize: 18 }}>
                      {contract["vlera"]}
                    </div>
                  )
                ) : (
                  service.fields.map((field: any, index: any) => {
                    return (
                      <div key={index} style={{ fontSize: 18 }}>
                        {contract[field.label]}
                      </div>
                    );
                  })
                )}
              </div>

              <div
                style={{ padding: 10 }}
                onClick={async () => {
                  await deleteContract(contract);
                  selectContract(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  color={thisIsSelected ? "white" : Theme.textColor}
                />
              </div>
            </div>
          );
        })}

        {errorMsg !== "" && (
          <div
            style={{
              textAlign: "center",
              fontFamily: "Roboto-Regular",
              color: "red",
            }}
          >
            {errorMsg}
          </div>
        )}
        {!selected &&
          (!service.maxContractLength ||
            contracts.length < service.maxContractLength) && (
            <button
              style={{ margin: 10 }}
              onClick={async () => {
                const obj = await saveContract(userInputs as any);
                if (!errorMsg && !!obj) {
                  setShouldReload(true);
                  setUserInputs(new Array(service.fields.length));
                  selectContract(obj);
                  setTimeout(() => onBackdropPress(), 200);
                }
              }}
            >
              {langText(contracts?.length > 0 ? "add" : "continue")}
            </button>
          )}
      </div>
    </Modal>
  );
};
export default RegisterBillModal;
