import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import {
  ActivateProduct,
  CheckUserPIN,
  InitTransaction,
  InvoicePayment,
  MobileTopUp,
  PayInvoice,
  PayToBusinessTransaction,
  ProfilePicture,
  UKTPaymentEndpoint,
} from "../constants/endpoints";
import Modal from "../components/Modal";
import Lottie from "react-lottie";
import animationData from "../assets/782-check-mark-success.json";
import Sidebar from "../components/Sidebar";
import { useAppSelector } from "../hooks/reduxHooks";
import ChooseAccountButton from "../components/ChooseAccountButton";
import { logActivity } from "../hooks/functions";
import langText from "../lang/i18n";
import { getCurrentServer, logOutClearDB } from "../localDatabase/mainDB";

export default function ConfirmPayment() {
  const { UserToken, chosenAcc, PersonId } = useAppSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { friendInfo, amount, editable, logo } = location.state;
  logActivity("friendInfo", friendInfo, "amount", amount, "editable", editable);
  const a = {
    friendInfo: {
      name: "OSHEE",
      recipientCurrency: "ALL",
      phoneNo: "+355688109537",
      operatorId: 24,
      description: "{}",
      invoiceNo: "",
      documentNo: "451084160",
      contractNo: "EL0C140005075404",
    },
    amount: 1983.6,
    editable: false,
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [pin, setPin] = useState("");
  const [pinVerified, setPinVerified] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("0");
  const [message, setMessage] = useState("");
  const amountRef = React.useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");

  useEffect(() => {
    if (!amount) return;
    if (friendInfo.userId) {
      setImage(ProfilePicture(friendInfo.userId));
    } else {
      setImage(logo);
    }
    setPaymentAmount(amount.toString());
  }, []);

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  const handleChange = (event: any) => {
    logActivity(event.target.value);
    const text = (
      event.target.value === "" ? "0" : event.target.value
    ).replaceAll(",", "");

    if (isNaN(text) && !(text.endsWith(".") && text.split(".").length === 2))
      return;
    setPaymentAmount(text);
  };

  const formattedPaymentAmount =
    new Intl.NumberFormat("en-US", {}).format(Number(paymentAmount)) +
    (!!paymentAmount && paymentAmount.endsWith(".") ? "." : "");

  useEffect(() => {
    if (pin.length === 4) {
      if (getCurrentServer() === "dev") setPinVerified(true);
      else checkPIN();
    }
  }, [pin]);

  function checkPIN() {
    try {
      fetch(CheckUserPIN(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
        body: JSON.stringify({
          person: PersonId,
          pin: pin,
        }),
      }).then((response) => {
        logActivity("Check pin");
        logActivity(JSON.stringify(response));
        if (response.status === 406) {
          logActivity("Bad  pin");
          setErrorMsg("Incorrect PIN");
          setPin("");
        } else if (response.status === 200) {
          setPinVerified(true);
        } else if (response.status === 404) {
          setErrorMsg(
            "You don't have a PIN. Go to settings screen to set your PIN"
          );
          setPin("");
        } else {
          setErrorMsg("error");
          setPin("");
        }
      });
    } catch (e) {
      logActivity(e);
    }
  }

  function getEndpoint() {
    return friendInfo?.operatorId === 22
      ? UKTPaymentEndpoint()
      : friendInfo.topUpFirst === true || friendInfo.topUpFirst === undefined
      ? friendInfo.documentNo
        ? InvoicePayment()
        : friendInfo.invoiceNo
        ? PayInvoice()
        : MobileTopUp()
      : ActivateProduct();
  }

  function makeTransaction() {
    logActivity("starting transaction");

    const body =
      friendInfo?.operatorId == 22
        ? {
            contractNumber: friendInfo.contractNo,
            name: friendInfo.name,
            referenceId: "WSTEST",
            serialNumber: friendInfo.serialNumber,
            totalInvoiceAmmount: amount,
            customerId: PersonId,
            operatorId: 22,
            account: chosenAcc?.id,
          }
        : friendInfo?.operatorId == 26
        ? {
            operatorId: "26",
            customerId: PersonId,
            amount: parseFloat(paymentAmount.toString()),
            currency: "ALL",
            invoiceNo: friendInfo.invoiceNo,
            account: chosenAcc?.id,
          }
        : !friendInfo.userId
        ? {
            customerId: PersonId,
            account: chosenAcc?.id,
            token: UserToken,
            amount: parseFloat(paymentAmount.toString()),
            currency: friendInfo.recipientCurrency,
            phoneNo: friendInfo.phoneNo,
            operatorId: friendInfo.operatorId,
            description: friendInfo.description,
            invoiceNo: friendInfo.invoiceNo,
            documentNo: [friendInfo.documentNo],
            contractNo: friendInfo.contractNo,
            customerNo: friendInfo.customerNo,
            customerName: "",
            date: friendInfo.date ?? "",
            vendorName: "ASBS",
          }
        : {
            amount: parseFloat(paymentAmount.toString()),
            currency: chosenAcc?.currency,
            customer: friendInfo.userId,
            message: message,
            account: chosenAcc?.id,
            paymentRequest: friendInfo.paymentRequest,
            businessUnit: friendInfo.businessUnit,
          };

    (friendInfo.id
      ? fetch(
          !!friendInfo.businessUnit
            ? PayToBusinessTransaction()
            : InitTransaction(),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + UserToken,
            },
            body: JSON.stringify(body),
          }
        )
      : fetch(getEndpoint(), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          body: JSON.stringify(body),
        })
    ).then((response) => {
      logActivity(getEndpoint(), response.status);

      if (!response.ok) {
        setErrorMsg("error");
        return;
      }
      if (response.status === 201 || response.status === 200) {
        logActivity("transaction successful");
        setPaymentSuccessful(true);
      }
      response
        .json()
        .then((res) => {
          logActivity(res);
        })
        .catch((e) => {
          setErrorMsg("error");
          logActivity(e);
        });
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#F5F5F5",
      }}
    >
      <Sidebar />
      <div
        style={{
          width: "100%",
          height: "100%",
          marginLeft: "7rem",
          minHeight: "100vh",
        }}
      >
        <br />
        <Header title={"Dërgo para tek"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "60vh",
              backgroundColor: "#FFFFFF",
              borderRadius: "15px",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  margin: "0 auto 20px auto",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#546BEA",
                }}
              >
                <img
                  src={image}
                  onError={(event: any) => {
                    event.target.src = require("../assets/pago-logo-round-small.png");
                    event.onerror = null;
                  }}
                  alt="Profile"
                  style={{ width: "100%", height: "100%", borderRadius: 100 }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "32px",
                    fontFamily: "Poppins",
                    color: "#546BEA",
                  }}
                >
                  {friendInfo.name}
                </span>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <ChooseAccountButton
                diff={Number(paymentAmount)}
                style={{ borderRadius: "4px" }}
              />
              <br />
              <p
                style={{
                  fontSize: 20,
                  fontFamily: "Poppins",
                  fontWeight: "100",
                  marginLeft: 0,
                }}
              >
                Vendos shumën që doni të dërgoni
                <span style={{ color: "red" }}>*</span>
              </p>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  border: "1px solid #C5C5C5",
                  borderRadius: "4px",
                  paddingRight: 10,
                }}
                onClick={() => {
                  amountRef.current?.focus();
                }}
              >
                <input
                  ref={amountRef}
                  type="text"
                  value={formattedPaymentAmount}
                  onChange={handleChange}
                  maxLength={20}
                  disabled={editable === false}
                  style={{
                    display: "flex",
                    flex: 1,
                    minWidth: 100,
                    padding: "10px",
                    fontSize: "1.5em",
                    textAlign: "right",
                    fontFamily: "Poppins",
                    fontWeight: "100",
                    border: "none",
                    outline: "none",
                  }}
                />

                <span
                  style={{
                    fontSize: "1.5em",
                    marginLeft: 10,
                    fontFamily: "Poppins",
                  }}
                >
                  {chosenAcc?.currency === "ALL" ? "Lekë" : "Euro"}
                </span>
              </div>
            </div>

            <br />
            {!logo && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <p
                  style={{
                    fontSize: 20,
                    fontFamily: "Poppins",
                    fontWeight: "100",
                    marginLeft: 0,
                  }}
                >
                  Vendos një mesazh
                </p>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  disabled={editable === false}
                  style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    borderRadius: "4px",
                    padding: "10px",
                    fontSize: "1.5em",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "100",
                    resize: "none",
                    border: "1px solid #C5C5C5",
                  }}
                />
              </div>
            )}
            <button
              style={{
                width: "90%",
                height: "68px",
                padding: "15px",
                alignContent: "center",
                backgroundColor: "#636FED",
                color: "white",
                borderRadius: "4px",
                fontSize: 24,
                border: "none",
                justifyContent: "center",
                fontFamily: "Poppins",
                marginBottom: "50px",
                fontWeight: "600",
                marginTop: "10%",
                alignItems: "center",
                opacity:
                  loading ||
                  Number(paymentAmount) >
                    (chosenAcc?.balance.amount.value ?? 0) ||
                  Number(paymentAmount) <= 0
                    ? 0.5
                    : 1,
              }}
              onClick={() => {
                logActivity(friendInfo);
                setPinVerified(
                  isNaN(Number(paymentAmount))
                    ? false
                    : Number(paymentAmount) < 2000
                );
                setModalVisible(true);
              }}
              disabled={
                loading ||
                Number(paymentAmount) >
                  (chosenAcc?.balance.amount.value ?? 0) ||
                Number(paymentAmount) <= 0
              }
            >
              {langText("continue")}
            </button>
          </div>
        </div>
        <Modal
          isVisible={modalVisible}
          setIsVisible={(bool) => {
            if (!bool && paymentSuccessful) {
              setPaymentSuccessful(false);
              setPaymentAmount("0");
              navigate("/");
            }
            setPin("");
            setModalVisible(bool);
          }}
          title={
            paymentSuccessful
              ? "Transferta u krye me sukses"
              : "Konfirmo pagesën"
          }
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {paymentSuccessful ? (
              <div>
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  height={400}
                  width={400}
                />

                <button
                  style={{
                    marginTop: 10,
                    width: "100%",
                    padding: "10px",
                    backgroundColor: "#546BEA",
                    color: "white",
                    borderRadius: "10px",
                    fontSize: 18,
                    border: "none",
                    fontFamily: "Poppins",
                  }}
                  onClick={() => {
                    setModalVisible(false);
                    setPaymentSuccessful(false);
                    setPaymentAmount("0");
                    navigate("/");
                  }}
                  disabled={loading}
                >
                  {langText("continue")}
                </button>
              </div>
            ) : !pinVerified ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <label htmlFor="pin">Enter Pin:</label>
                <input
                  id="pin"
                  type="password"
                  value={pin}
                  onChange={(e) => setPin(e.target.value)}
                  maxLength={4}
                  style={{
                    border: "1px solid grey",
                    padding: "10px",
                    width: "50%",
                    borderRadius: "10px",
                    fontSize: "1.5em",
                    textAlign: "center",
                    fontFamily: "Poppins",
                  }}
                />
                <span
                  style={{
                    color: "red",
                    fontSize: "1.2em",
                    marginTop: "10px",
                  }}
                >
                  {errorMsg}
                </span>
              </div>
            ) : (
              <button
                style={{
                  marginTop: 10,
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "#546BEA",
                  color: "white",
                  borderRadius: "10px",
                  fontSize: 18,
                  border: "none",
                  fontFamily: "Poppins",
                }}
                onClick={() => {
                  navigate("/");
                  makeTransaction();
                }}
                disabled={loading}
              >
                Paguaj
              </button>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}
