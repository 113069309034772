import { useState, useEffect } from "react";
import moment from "moment";
import { getUser, logOutClearDB } from "../localDatabase/mainDB";
import Sidebar from "./Sidebar";
import langText from "../lang/i18n";
import { Tabs, ConfigProvider, Button, Alert } from "antd";
import { useAppSelector } from "../hooks/reduxHooks";
import { GetBookings, StatementBydate } from "../constants/endpoints";
import { SingleStatementAPIKEY } from "../Network";
import downloadIcon from "../assets/icons/downloadIcon.svg";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "./LoadingIndicator";

export default function StatementExport() {
  const { chosenAcc, UserToken } = useAppSelector((state) => state.user);

  const { SelectedColor, Theme } = useAppSelector((state) => state.theme);

  const [years, setYears] = useState<number[]>([2021, 2022]);
  const [currentTab, setcurrentTab] = useState("");
  const [selectedYear, setSelectedYear] = useState<number>();
  const [error, setError] = useState("");
  const [isDownloading, setIsDownloading] = useState({
    loading: false,
    month: "",
  });
  const navigate = useNavigate();

  const UserData = getUser();

  const currentDate = new Date();

  const months = [
    langText("jan"),
    langText("feb"),
    langText("mar"),
    langText("apr"),
    langText("may"),
    langText("jun"),
    langText("jul"),
    langText("aug"),
    langText("sep"),
    langText("oct"),
    langText("nov"),
    langText("dec"),
  ];

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setSelectedYear(currentYear);
    let tempYears = [];
    let date = new Date(UserData.termsConditionsSignedAt);
    for (let i = currentYear; i >= date.getFullYear() ?? 2022; i--) {
      tempYears.push(i);
    }
    setYears(tempYears);
  }, []);

  async function getBookings(startDate: any, endDate: any) {
    const body = {
      account: chosenAcc?.id,
      from: !startDate
        ? undefined
        : moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
      to: !endDate
        ? undefined
        : moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS"),
    };

    const response = await fetch(GetBookings("?max=10000"), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) return;
    const resData = await response.json();

    if (!resData) return;
    return resData;
  }

  const downloadPDF = async (startDate: any, endDate: any, month: string) => {
    setIsDownloading({ loading: true, month });
    const bookings = await getBookings(startDate, endDate);

    if (!bookings || bookings.length === 0) {
      setError(langText("no_data_this_month"));
      setIsDownloading({ loading: false, month });
      return;
    }

    const body = JSON.stringify({
      data: {
        name: UserData.name,
        count: bookings.length,
        currency: chosenAcc?.currency,
        from: startDate,
        to: endDate,
        account: chosenAcc?.id,
      },
      bookings: bookings,
    });

    fetch(StatementBydate(), {
      method: "POST",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: "Bearer " + UserToken,
        "API-KEY": SingleStatementAPIKEY,
      },
      body: body,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "statement.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setIsDownloading({ loading: false, month });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onChange = (key: string) => {
    setcurrentTab(key);
    setSelectedYear(parseInt(key));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Sidebar />
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginLeft: "7rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 60,
          }}
        >
          <p
            className="depozito"
            style={{
              color: "white",
              marginLeft: "40px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              marginTop: "20px",
            }}
          >
            {langText("extract")}
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              fontSize: "24px",
              lineHeight: "36px",
              color: "#FFFFFF",
              marginLeft: "40px",
            }}
          ></p>
        </div>

        <div className="columns-container">
          <div className="column">
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    titleFontSize: 24,
                    itemSelectedColor: SelectedColor,
                    algorithm: true, // Enable algorithm
                  },
                },
              }}
            >
              <Tabs
                onChange={onChange}
                type="card"
                items={years.map((i) => {
                  return {
                    label: "Viti" + " " + i.toString(),
                    key: i.toString(),
                    children: ``,
                  };
                })}
              />
              {error.length !== 0 && (
                <Alert
                  style={{ margin: "10px 0px" }}
                  message={error}
                  type="warning"
                />
              )}

              {months.map((month, index) => {
                let startDate = new Date(UserData.termsConditionsSignedAt);
                if (
                  (index > currentDate.getMonth() &&
                    currentDate.getFullYear() == selectedYear) ||
                  (index < startDate.getMonth() &&
                    startDate.getFullYear() == selectedYear)
                )
                  return;
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0.3em 1.3em",
                      backgroundColor:
                        index % 2
                          ? Theme.secondaryContainerColor
                          : Theme.darkerContainerColor,
                      fontFamily: "Roboto-Regular",
                      fontSize: 20,
                    }}
                  >
                    <div> {month}</div>
                    <div
                      onClick={() => {
                        if (selectedYear) {
                          downloadPDF(
                            new Date(selectedYear, index, 2)
                              .toISOString()
                              .substr(0, 10),
                            new Date(selectedYear, index + 1, 2)
                              .toISOString()
                              .substr(0, 10),
                            month
                          );
                          setError("");
                        }
                      }}
                    >
                      {isDownloading.loading &&
                      isDownloading.month === month ? (
                        <LoadingSpinner />
                      ) : (
                        <img src={downloadIcon} />
                      )}
                    </div>
                  </div>
                );
              })}
            </ConfigProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
