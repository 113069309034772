import { AccountProps, UserDataProps } from "../redux/user-slice";
import { MockHomeData } from "./mockData";
import { logActivity } from "../hooks/functions";

export const getCurrentServer = () => {
  return JSON.stringify("prod");
  //JSON.parse(localStorage.getItem("server") ?? JSON.stringify("prod"));
};
export const setCurrentServer = (server: string) => {
  localStorage.setItem("server", JSON.stringify(server));
};

export const logOutClearDB = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const getIsLoggedIn = () => {
  return JSON.parse(localStorage.getItem("is_logged_in") ?? "false");
};
export const setIsLoggedIn = (value: boolean) => {
  localStorage.setItem("is_logged_in", JSON.stringify(value));
};

export const getLang = () => {
  return localStorage.getItem("lang");
};
export const setLanguage = (lang: string) => {
  localStorage.setItem("lang", lang);
};

export const setUser = (user: UserDataProps) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const getUser = () => {
  const stringUser = localStorage.getItem("user");
  if (!!stringUser && stringUser !== "undefined") {
    return JSON.parse(stringUser) as UserDataProps;
  }
  return {} as UserDataProps;
};

export const setDBUserToken = (token: string) => {
  localStorage.setItem("token", token);
};

export const getUserToken = () => {
  return localStorage.getItem("token") ?? "";
};

export const setDBAccountData = (data: {
  chosenAcc: AccountProps;
  accounts: AccountProps[];
}) => {
  localStorage.setItem("account_data", JSON.stringify(data));
};

export const getAccountData = () => {
  return JSON.parse(localStorage.getItem("account_data") ?? "null") as {
    chosenAcc: AccountProps;
    accounts: AccountProps[];
  };
};
export const setDBChosenAcc = (chosenAcc: AccountProps) => {
  localStorage.setItem("chosenAcc", JSON.stringify(chosenAcc));
};

export const getDBChosenAcc = () => {
  return JSON.parse(
    localStorage.getItem("chosenAcc") ?? JSON.stringify(null)
  ) as AccountProps;
};
export const setDBAccounts = (accounts: AccountProps[]) => {
  localStorage.setItem("allAccounts", JSON.stringify(accounts));
};

export const getDBAccounts = () => {
  const accounts = JSON.parse(
    localStorage.getItem("allAccounts") ??
      JSON.stringify(getCurrentServer() === "dev" ? MockHomeData.accounts : [])
  ) as AccountProps[];
  logActivity("DB accounts", accounts);
  return accounts;
};

export const setNotificationsRegistered = (value: boolean) => {
  localStorage.setItem("notifications", JSON.stringify(value) ?? "false");
};

export const getNotificationIsRegistered = () => {
  return JSON.parse(localStorage.getItem("notifications") ?? "false");
};

export function setPrivacyActive(status: boolean) {
  return localStorage.setItem("PRIVACY", status.toString());
}

export function getPrivacyActive() {
  return JSON.parse(localStorage.getItem("PRIVACY") ?? "false");
}
