import { format } from "date-fns";
import { sq } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import { getUser } from "../localDatabase/mainDB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faCheckCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import langText from "../lang/i18n";
import { ServiceProps } from "../constants/Utilities";
import "./InvoiceItem.css";
import { formatMoney } from "../functions";
import calendarImage from "../assets/icons/calendar.svg";
import carImage from "../assets/icons/car.svg";
import userImage from "../assets/icons/Group.svg";
import moneyImage from "../assets/icons/moneys.svg";

export default function InvoiceItem({
  bill,
  service,
  utility,
}: {
  bill: any;
  service: ServiceProps;
  utility: any;
}) {
  const navigate = useNavigate();
  const UserData = getUser();

  if (bill.loading) {
    return (
      <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
        <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#546BEA" />
      </div>
    );
  }

  if (bill.message) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          color: "#546BEA",
          marginTop: 10,
          marginBottom: 30,
        }}
      >
        <div style={{ marginRight: 10 }}>
          <FontAwesomeIcon icon={bill.error ? faTimes : faCheckCircle} />
        </div>
        <div>{bill.message}</div>
      </div>
    );
  }

  function renderHeaderText(title: string, isLast: boolean) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "grey",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontWeight: "500",
        }}
      >
        <div>{title + (isLast ? "" : ", ")}</div>
      </div>
    );
  }

  if (
    !bill.SerialNumber &&
    !bill.serial_number &&
    !bill.Seria &&
    !bill.detyrimi &&
    !bill.customer_id &&
    !bill.customerNo
  ) {
    let keys = Object.keys(bill);
    return (
      <div>
        <div
          style={{
            margin: 10,
            marginLeft: 25,
            fontSize: "20px",
            left: "0px",
            top: "60px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            color: "#414141",
            borderBottom: "1px solid #EFEFEF",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {keys.map((key, index) => {
              if (key === "onPress") return null;
              if (typeof bill[key] === "object") {
                return Object.keys(bill[key]).map((key2, index2) => {
                  return renderHeaderText(
                    bill[key][key2],
                    index2 === Object.keys(bill[key]).length - 1
                  );
                });
              }
              return renderHeaderText(
                bill[key],
                index === keys.length - 1 || keys[index + 1] === "onPress"
              );
            })}
            {bill.onPress != null && (
              <button
                style={{
                  padding: 10,
                  border: "none",
                  background: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  bill.onPress();
                }}
              >
                <FontAwesomeIcon
                  size={"2x"}
                  icon={faTimesCircle}
                  color={"#546BEA"}
                />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  let date = new Date(
    bill.invoiceMthYear
      ? bill.invoiceMthYear
          .split("/")
          .reverse()
          .map((item: string) => item.padStart(2, "0"))
          .join("-")
      : bill.InvoiceDate ??
        bill.document_date?.replace(" ", "T") ??
        bill.Data ??
        bill.invoice_date ??
        new Date().toString()
  );

  const hasExtraCosts =
    (bill.InvoiceOverDueAmount !== undefined &&
      bill.InvoiceOverDueAmount !== "") ||
    (bill.total_interest_overdue !== undefined &&
      bill.total_interest_overdue !== "0");

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "20px",
        borderRadius: "25px",
        backgroundColor: "#ffffff",
        border: "1px solid #546BEA",
        marginBottom: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="amount-style">
            {formatMoney(
              bill.totalAmt ??
                bill.InvoiceTotalAmount ??
                bill.debt ??
                bill.detyrimi ??
                bill.invoice_value ??
                bill.total_invoice_ammount ??
                (bill.Vlera
                  ? parseFloat(bill.Vlera) + parseFloat(bill.Kamata)
                  : "")
            ) + " Lekë"}
          </div>

          {bill.Seria && (
            <div
              style={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "27px",
                color: "#858585",
              }}
            >
              Targa&nbsp;
              <span>{bill.contract.vlera}</span>
            </div>
          )}

          <div
            style={{
              fontFamily: "'Poppins'",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "27px",
              color: "#858585",
            }}
          >
            <span
              style={{
                margin: "0px 0px",
                textAlign: "center",
                marginRight: "10px",
              }}
            >
              <img src={calendarImage} alt="calendar" />
            </span>
            {(bill.Seria
              ? date.toTimeString().substring(0, 5) +
                ", " +
                date.getDate() +
                " "
              : "") +
              format(
                bill.gjeneruar
                  ? new Date(
                      parseInt(bill?.gjeneruar?.split("/")[2] ?? 2022),
                      parseInt(bill?.gjeneruar?.split("/")[1] ?? 1) - 1
                    )
                  : date,
                "MM yyyy",
                { locale: sq }
              )}
          </div>
        </div>

        <button
          style={{
            backgroundColor: "#81C45E",
            padding: "12px 60px",
            color: "white",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "18px",
            boxShadow: "0px 2px 4px rgba(84, 107, 234, 0.4)",
          }}
          onClick={() => {
            navigate("/confirm-payment", {
              state: {
                friendInfo: {
                  name: utility.short_name,
                  recipientCurrency: "ALL",
                  phoneNo: bill.SubscriberMsisdn
                    ? bill.SubscriberMsisdn
                    : UserData.mobileNumber,
                  operatorId: parseInt(
                    service.operatorId ?? utility.operatorId
                  ),
                  description: JSON.stringify({
                    account: bill.SubscriberMsisdn ? 0 : bill.CustomerCode,
                  }),
                  invoiceNo: bill.SerialNumber ?? bill.kodFature ?? "",
                  documentNo:
                    bill.serial_number ??
                    bill.Seria ??
                    bill.kodFature ??
                    bill.invoice_id ??
                    bill.invoiceNo ??
                    "",
                  contractNo:
                    bill?.contract.vlera != undefined
                      ? bill.contract.vlera
                      : bill?.contract[Object?.keys(bill?.contract)[0]],
                  customerNo: bill.customer_id,
                  date: bill.InvoiceDate,
                  serialNumber: bill.serial_number,
                },
                amount: parseFloat(
                  bill.Seria
                    ? parseFloat(bill.Vlera) + parseFloat(bill.Kamata)
                    : bill.InvoiceTotalAmount
                    ? bill.InvoiceTotalAmount.toString().replace(/,/g, "")
                    : bill.debt ??
                      bill.detyrimi ??
                      bill.invoice_value ??
                      bill.totalAmt ??
                      bill.total_invoice_ammount
                ),
                message: utility.short_name,
                editable: false,
                logo: utility?.logo(),
              },
            });
          }}
        >
          {langText("pay_now")}
        </button>
      </div>

      <hr />

      <div>
        {hasExtraCosts && (
          <span style={{ fontSize: 18, margin: "0px 20px" }}>
            <img src={moneyImage} alt="User" />
            {langText("kamata") +
              " " +
              ((bill.InvoiceDebt ?? bill.total_with_vat) +
                " + " +
                (bill.InvoiceOverDueAmount ?? bill.total_interest_overdue))}
          </span>
        )}
      </div>

      {bill.Seria && (
        <div style={{ margin: "0px 20px" }}>
          {bill.Kamata !== undefined && bill.Kamata !== "0" && (
            <div>
              <div className="fine-amount">
                <span>
                  <img className={"details-icon"} src={moneyImage} alt="User" />{" "}
                </span>
                <span className="fine-amount">{langText("kamata")}</span>{" "}
                <span className="fine-amount">
                  {bill.Kamata + " " + langText("ALL")}
                </span>
              </div>
            </div>
          )}

          <div className="fine-amount">
            <span>
              {" "}
              <img src={carImage} alt="Mjeti" />{" "}
            </span>
            {bill.MjetiMarka}
          </div>
          <div className="fine-amount">
            <span>
              {" "}
              <img src={userImage} alt="User" />{" "}
            </span>
            {bill.MjetiPronari.replace(/ÃƒÂ¿/g, "Ç")
              .replace(/ÃƒÂ§/g, "Ç")
              .replace(/Ã‡/g, "Ç")
              .replace(/ÃƒÂ«/g, "ë")}
          </div>
        </div>
      )}
    </div>
  );
}
