import { Button } from "reactstrap";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "./FilterComponents.css";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export const FilterComponent = ({
  filterText,
  onFilter,
  onClear = () => onFilter({ target: { value: "" } }),
  placeholder = "Kërko",
}: {
  filterText: string;
  onFilter: (event: any) => void;
  onClear?: () => void;
  placeholder?: string;
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          padding: "8px 12px",
          backgroundColor: "#F5F5F5",
        }}
        onClick={() => {
          inputRef.current?.focus();
        }}
      >
        <div>
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={filterText}
          onChange={onFilter}
          style={{
            border: "none",
            padding: "0px 16px",
            backgroundColor: "transparent",
            boxShadow: "none",
            outline: "none",
            flex: 1,
          }}
        />

        <div style={{ opacity: filterText !== "" ? 1 : 0 }} onClick={onClear}>
          <FontAwesomeIcon icon={faTimes} size={"lg"} />
        </div>
      </div>
    </>
  );
};

export const FilterDateComponent = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onClear,
}: {
  startDate?: Date;
  endDate?: Date;
  setStartDate: (event: any) => void;
  setEndDate: (event: any) => void;
  onClear: () => void;
}) => {
  // const [selectDateFlip, setSelectedDateFlip] = useState(false);
  //
  // useEffect(() => {
  //   setSelectedDateFlip(!selectDateFlip);
  // }, [startDate, endDate]);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginRight: 20,
        }}
      >
        <DatePicker
          className="date-picker"
          clearButtonClassName="clear-button"
          dayClassName={(date) =>
            !startDate
              ? "default-day"
              : format(date, "dd/MM/yyyy") === format(startDate, "dd/MM/yyyy")
              ? "selected-day start-day"
              : !endDate
              ? "default-day"
              : format(date, "dd/MM/yyyy") === format(endDate, "dd/MM/yyyy")
              ? "selected-day end-day"
              : startDate.getTime() < date.getTime() &&
                endDate.getTime() > date.getTime()
              ? "selected-day"
              : "default-day"
          }
          // showIcon
          // value={
          //   !startDate
          //     ? ""
          //     : format(startDate, "HH:mm dd/MM/yyyy") +
          //       (!endDate ? "" : " - " + format(endDate, "HH:mm dd/MM/yyyy"))
          // }
          placeholderText={"Filtroni sipas dates"}
          onChange={onChange}
          // selectsStart={selectDateFlip}
          // selectsEnd={!selectDateFlip}
          startDate={startDate}
          endDate={endDate}
          dateFormat={"dd/MM/yyyy"}
          timeFormat={"HH:mm"}
          // showTimeInput
          isClearable
          selectsRange
        />
      </div>
    </>
  );
};
