import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import LoginScreen from "./Screens/LoginScreen";
import UtilitiesScreen from "./Screens/Utilities/UtilitiesScreen";
import TransactionDetails from "./Screens/TransactionDetails";
import ConfirmPayment from "./Screens/ConfirmPayment";
import UtilityServiceScreen from "./Screens/Utilities/UtilityServiceScreen";
import InvoicesScreen from "./Screens/Utilities/InvoicesScreen";
import { Id, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileTopUp from "./Screens/Utilities/MobileTopUp";
import DepositScreen from "./Screens/DepositScreen";
import PagoCardScreen from "./Screens/PagoCardScreen";
import TransferToFriendScreen from "./Screens/TransferToFriendScreen";
import {
  getUserToken,
  logOutClearDB,
  setCurrentServer,
  setIsLoggedIn,
} from "./localDatabase/mainDB";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import {
  DEV_BACKEND,
  LoginUrl,
  LogoutUrl,
  switchBackend,
} from "./constants/endpoints";
import { logActivity } from "./hooks/functions";
import langText from "./lang/i18n";
import SettingsScreen from "./Screens/Settings/SettingsScreen";
import { ModalProvider } from "./components/modals/ModalService";
import ForgotPasswordScreen from "./Screens/ForgotPasswordScreen";
import SMSVerificationScreen from "./Screens/SMSVerificationScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen";
import StatementExport from "./components/StatementExport";
import MerchantsScreen from "./Screens/Merchants/MerchantsScreen";

const App = () => {
  // Access location object from useLocation hook
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginScreen />,
    },
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "/*",
      element: <HomeScreen />,
    },
    {
      path: "/utilities",
      element: <UtilitiesScreen />,
    },
    {
      path: "/utility-service",
      element: <UtilityServiceScreen />,
    },

    {
      path: "/invoices",
      element: <InvoicesScreen />,
    },

    {
      path: "/top-up",
      element: <MobileTopUp />,
    },

    {
      path: "/transaction-details",
      element: <TransactionDetails />,
    },

    {
      path: "/confirm-payment",
      element: <ConfirmPayment />,
    },

    {
      path: "/transfer",
      element: <TransferToFriendScreen />,
    },

    {
      path: "/deposit",
      element: <DepositScreen />,
    },

    {
      path: "/pago-card",
      element: <PagoCardScreen />,
    },
    {
      path: "/settings",
      element: <SettingsScreen />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordScreen />,
    },
    {
      path: "/sms-verification",
      element: <SMSVerificationScreen />,
    },
    {
      path: "/reset-password",
      element: <ResetPasswordScreen />,
    },
    {
      path: "/extract",
      element: <StatementExport />,
    },
    {
      path: "/merchants",
      element: <MerchantsScreen />,
    },
  ]);

  let mainTimeout: NodeJS.Timeout | null = null;
  let notificationTimeout: NodeJS.Timeout | null = null;
  let notificationId: Id;

  useEffect(() => {
    if (
      // eslint-disable-next-line no-restricted-globals
      location.hostname === "localhost" ||
      // eslint-disable-next-line no-restricted-globals
      location.hostname === "127.0.0.1"
    ) {
      setCurrentServer("dev");
    } else setCurrentServer("prod");

    restartAutoReset();
    const sub = router.subscribe((route) => {
      if (route.location.pathname === "/login") return;
      restartAutoReset();
    });
    const storeSub = store.subscribe(restartAutoReset);
    return () => {
      if (!!sub) sub();
      if (!!storeSub) storeSub();
    };
  }, []);

  function restartAutoReset() {
    if (window.location.pathname === "/login") return;

    if (mainTimeout) {
      clearTimeout(mainTimeout);
      if (notificationTimeout) clearTimeout(notificationTimeout);
      toast.dismiss(notificationId);
    }
    notificationTimeout = setTimeout(() => {
      logActivity("Toast");
      notificationId = toast.warn(
        <div>
          <h1>{langText("inactivity_warning")}</h1>
          <h3>{langText("inactivity_warning_message")}</h3>
        </div>,
        {
          autoClose: 1000 * 60 * 1, //1 min
        }
      );
    }, 1000 * 60 * 4 - 500); // 4 * 1 minute 500ms delay
    mainTimeout = setTimeout(() => {
      logOut();
      setIsLoggedIn(false);
      router.navigate("/login");
      notificationId = toast.warn(langText("you_have_been_logged_out"), {
        autoClose: false,
      });
    }, 1000 * 60 * 5); // 5 * 1 minute
  }

  async function logOut() {
    const token = getUserToken();
    if (token.length > 0) {
      await fetch(LogoutUrl(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
    }
    logOutClearDB();
  }

  function breakInactivity(e: React.MouseEvent<HTMLElement>) {
    logActivity("Breaking inactivity");
    restartAutoReset();
  }

  return (
    <div onClick={breakInactivity}>
      <Provider store={store}>
        <ModalProvider>
          <RouterProvider router={router} />
          <ToastContainer />
        </ModalProvider>
      </Provider>
    </div>
  );
};

export default App;
