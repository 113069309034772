import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { format } from "date-fns";

import Sidebar from "../components/Sidebar";
import { useAppSelector } from "../hooks/reduxHooks";
import { logActivity } from "../hooks/functions";
import { ProfilePicture, SingleStatement } from "../constants/endpoints";
import { logOutClearDB } from "../localDatabase/mainDB";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { SingleStatementAPIKEY } from "../Network";
import langText from "../lang/i18n";
import LoadingSpinner from "../components/LoadingIndicator";

export default function TransactionDetails() {
  const { UserData } = useAppSelector((state) => state.user);

  const { chosenAcc } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const location = useLocation();
  const item = location.state.booking as any;
  const navigate = useNavigate();
  const { booking } = location.state;

  const otherParty =
    booking.senderCustomer?.id !== chosenAcc?.accountHolder?.id
      ? booking.senderCustomer
      : booking.recipientCustomer;

  const initials = otherParty.name
    .split(" ")
    .map((n: string) => n[0])
    .join("");

  logActivity("booking", booking);
  logActivity("otherParty", otherParty);

  const { UserToken } = useAppSelector((state) => state.user);
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  const downloadStatement = async () => {
    setLoading(true);

    let body = JSON.stringify({
      ...item,
      senderCustomer: {
        ...item.senderCustomer,
        mobileNumber: UserData?.mobileNumber,
      },
      amount: item.amount.value,
      description: item.description ?? "-",
      mandateReference: item.description.includes("(ref ")
        ? item.description.substring(
            item.description.indexOf("(ref ") + 5,
            item.description.indexOf(")", item.description.indexOf("(ref "))
          )
        : "-",
      bookingDate: item.creationDate,
      account: {
        id: chosenAcc?.id,
      },
      creditDebitIndicatorEnum: item.creditDebitIndicator.name,
    });

    fetch(SingleStatement(), {
      method: "POST",
      headers: {
        "API-KEY": SingleStatementAPIKEY,
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "statement.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setLoading(false);
      })
      .catch((error: any) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        height: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Sidebar />
      <div
        style={{
          width: "100%",
          height: "100%",
          marginLeft: "7rem",
          minHeight: "100vh",
        }}
      >
        <br />
        <Header title={"Detajet e transaksionit"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "60vh",
              backgroundColor: "#FFFFFF",
              borderRadius: "15px",
              padding: "0 20px",
            }}
          >
            <div
              style={{
                margin: "0 auto",
                borderRadius: "10px",
                backgroundColor: "white",
                padding: "20px",
                textAlign: "center",
              }}
            >
              <img
                src={booking.avatar}
                onError={(event: any) => {
                  event.target.src = require("../assets/pago-logo-round-small.png");
                  event.onerror = null;
                }}
                alt="Profile"
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 100,
                  margin: "0 auto 20px auto",
                }}
              />

              <div
                style={{
                  fontWeight: "700",
                  fontSize: "32px",
                  fontFamily: "Poppins",
                  color: "#546BEA",
                }}
              >
                {otherParty.name}
              </div>

              <div
                style={{
                  fontWeight: "400",
                  fontSize: "40px",
                  fontFamily: "Poppins",
                }}
              >
                {(booking.senderCustomer?.id === chosenAcc?.accountHolder?.id
                  ? "-" + booking.amount.value
                  : "+" + booking.amount.value) +
                  " " +
                  (chosenAcc?.currency === "ALL" ? "Lekë" : "Euro")}
              </div>
            </div>

            <br />
            <div
              style={{
                color: "#999",
                width: "100%",
                fontWeight: "600",
                fontFamily: "Poppins",
                display: "flex",
                flexDirection: "column",
                padding: "0em 1em",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0em 0em 1em 0em",
                }}
              >
                <label>Mesazhi: </label>
                {!!booking.description && (
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "20px",
                      borderRadius: "4px",
                      backgroundColor: "rgba(84, 107, 234, 0.7)",
                      padding: "15px",
                      color: "white",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      textAlign: "start",
                    }}
                  >
                    {booking.description}
                  </div>
                )}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "0em 0em 1em 0em",
                  justifyContent: "space-between",
                }}
              >
                <label>Data: </label>
                {format(new Date(booking.creationDate), "HH:mm dd/MM/yyyy")}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label>Shkarko faturën: </label>
                <div>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <FontAwesomeIcon
                      size={"lg"}
                      icon={faDownload}
                      onClick={downloadStatement}
                    ></FontAwesomeIcon>
                  )}
                </div>
              </div>
            </div>

            {booking.senderCustomer !== undefined &&
              booking.recipientCustomer !== undefined &&
              !otherParty.mobileNumber?.endsWith("B") && (
                <button
                  style={{
                    width: "100%",
                    height: "68px",
                    padding: "15px",
                    alignContent: "center",
                    backgroundColor: "#636FED",
                    color: "white",
                    borderRadius: "4px",
                    fontSize: 24,
                    border: "none",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    marginBottom: "50px",
                    fontWeight: "600",
                    marginTop: "10%",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    navigate("/confirm-payment", {
                      state: {
                        friendInfo: {
                          ...otherParty,
                          userId: otherParty.id,
                        },
                      },
                    });
                  }}
                >
                  Dërgo para
                </button>
              )}
          </div>
        </div>
        {/* <Modal isVisible={true} setIsVisible={() => {}} title={""}>
        <div>
          <div
            style={{
              marginTop:"-20px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "17px",
              lineHeight: "36px",
              textAlign: "center",
              color: "#000000",
              marginBottom: "20px",
              padding:20
            }}
          >
            Të sigurtë që doni të vazhdoni me rimbushjen e numrit{" "}
            +355 696476526 me shumën prej 500 Lekë?{" "}
          </div>

          <div
            style={{ display: "flex", justifyContent: "center", gap: "10px",marginTop:"7px" }}
          >
            <button
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // padding: "12px 145px",
                gap: "10px",
                width: "315.56px",
                height: "66px",
                left: "0px",
                top: "120px",
                background: "#FFFFFF",
                border: "1px solid #546BEA",
                borderRadius: "10px",
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '22px',
                color: '#546BEA',
                cursor: "pointer",
              }}
            >

              Jo, nuk dua të vazhdoj
            </button>
            <button
            style={{ display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // padding: '12px 145px',
            gap: '10px',
            width: '315.56px',
            height: '66px',
            left: '326.44px',
            top: '120px',
            background: '#546BEA',
            borderRadius: '10px',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '22px',
            cursor: "pointer",
            color: 'white',
            borderColor:"transparent"}}> Po, vazhdo </button>
          </div>
        </div>
      </Modal> */}
      </div>
    </div>
  );
}
