import React, { useState } from "react";
import QRCode from "qrcode.react";
import langText from "../lang/i18n";
import {
  BankAccounts,
  GetAccountsUrl,
  GetBankName,
  InitInnerTransaction,
  TransferMoney,
} from "../constants/endpoints";
import LoadingSpinner from "../components/LoadingIndicator";

import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { AccountProps, setAccounts } from "../redux/user-slice";
import ChooseAccountButton from "../components/ChooseAccountButton";
import { hexWithOpacity, logActivity } from "../hooks/functions";
import Lottie from "react-lottie";
import animationData from "../assets/782-check-mark-success.json";
import Modal from "../components/Modal";
import { Radio } from "antd";
import { RubiserAPIKEY } from "../Network";
import "./InnerTransfer.css";

interface FormData {
  name: string;
  lastName: string;
  phoneNumber: string;
}

const InnerTransfer: React.FC = () => {
  const { UserToken, chosenAcc, allAccounts, UserData } = useAppSelector(
    (store) => store.user
  );
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    lastName: "",
    phoneNumber: "",
  });
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [amount, setAmount] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [myAccountsTransfer, setMyAccountsTransfer] = useState(false);
  const [otherAccountType, setOtherAccountType] = useState(1);
  const [accountHolderName, setAccountHolderName] = useState("");
  const [IBAN, setIBAN] = useState("");
  const [currency, setCurrency] = useState(1);
  const [otherPagoAcc, setOtherPagoAcc] = useState<{ id: number }>();

  const [targetAccount, setTargetAccount] = useState<
    AccountProps | { id: number }
  >(allAccounts?.find((acc) => acc.id !== chosenAcc?.id)!);

  const qrCodeValue = `${formData.name} ${formData.lastName} - ${formData.phoneNumber}`;

  async function makeTransfer() {
    if (otherAccountType === 2) {
      setLoading(true);
      addBankAccounts();
      setLoading(false);
    } else {
      if (Number(amount.replace(/,/g, "")) <= 0) {
        setErrorMessage(langText("amount_larger_than_0"));
        return;
      }

      const reqBody = {
        currency: chosenAcc?.currency,
        amount: amount.replace(/,/g, ""),
        message: description,
        senderAccount: chosenAcc?.id,
        recipientAccount: myAccountsTransfer
          ? targetAccount.id
          : otherPagoAcc?.id,
      };
      logActivity(InitInnerTransaction(), reqBody);
      setLoading(true);
      const response = await fetch(InitInnerTransaction(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
        body: JSON.stringify(reqBody),
      });
      setLoading(false);
      logActivity("made a transaction", response.status);
      if (!response.ok) {
        setErrorMessage(langText("error"));
        return;
      }
      const responseJson = await response.json();
      logActivity(responseJson);
      setSuccess(true);
      getAllAccounts();
    }
  }

  async function addBankAccounts() {
    let bankName = await getBankName();
    if (!bankName) {
      return;
    }

    if (accountHolderName === "" || IBAN === "") {
      setErrorMessage(langText("fill_details"));
      setLoading(false);
      return;
    }

    setLoading(true);
    let body = JSON.stringify({
      customer: UserData!.id.toString(),
      iban: IBAN.replace(/ /g, ""),
      accountHolderName: accountHolderName,
      bankName: bankName,
      // swift: swift,
      currencyCode: currency === 1 ? "ALL" : "EUR",
      account: chosenAcc?.id,
    });
    logActivity("sending : ", body);
    fetch(BankAccounts(), {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: body,
    })
      .then((res) => {
        setLoading(false);
        logActivity("Add Bank Account " + res.status);
        res.json().then((data) => {
          logActivity("Add Bank Account data ", data);
          if (res.status === 200 || res.status === 201) {
            makeWireTransfer(data);
          }
        });
      })
      .catch((e) => {
        let err = Error(e);
        if (err.message === "TypeError: Network request failed") {
          setLoading(false);
        }
      });
  }

  function makeWireTransfer(bankAccount: any) {
    if (Number(amount.replace(/,/g, "")) <= 0) {
      setErrorMessage(langText("amount_larger_than_0"));
      return;
    }
    setLoading(true);
    let reqBody = JSON.stringify({
      fundingSource: bankAccount.id,
      account: chosenAcc?.id,
      transferType: "WIRE",
      currencyCode: chosenAcc?.currency,
      amount: amount,
      transferStatus: "PENDING",
      transferDirection: "OUTGOING",
      additionalInformation: description,
    });

    fetch(TransferMoney(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: reqBody,
    })
      .then((res) => {
        setLoading(false);
        logActivity("makeTransfer", res.status);
        if (res.status === 201) {
          setSuccess(true);
        } else {
          setErrorMessage(langText("error"));
        }
        res.json().then((resData) => {
          logActivity("makeTransfer", resData);
        });
      })
      .catch((e) => {
        setLoading(false);
        let err = Error(e);
        if (err.message === "TypeError: Network request failed") {
          setErrorMessage("Network request failed");
        }
      });
  }

  async function getBankName() {
    setLoading(true);

    if (!IBAN.startsWith("AL")) {
      setErrorMessage(langText("no_albanian_bank_found"));
      setLoading(false);
      return;
    }
    logActivity("getBankName", GetBankName(IBAN.replace(/ /g, "")));
    let res: any = await fetch(GetBankName(IBAN.replace(/ /g, "")), {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: RubiserAPIKEY,
      },
    }).catch((e) => {
      let err = Error(e);
      if (err.message === "TypeError: Network request failed") {
        setLoading(false);
      }
    });
    setLoading(false);
    logActivity("getBankName " + res.status);
    if (res.status === 200) {
      let data = await res.json();
      logActivity("data", data);
      return data.bankName;
    } else if (res.status === 422 || res.status === 404) {
      setErrorMessage(langText("invalid_iban"));
    } else {
      setErrorMessage(langText("error_iban"));
    }
  }

  const onAccountTypeChange = (e: any) => {
    setOtherAccountType(e.target.value);
  };

  const onCurrencyChange = (e: any) => {
    setCurrency(e.target.value);
  };

  async function getAllAccounts() {
    const response = await fetch(GetAccountsUrl(UserData!.id.toString()!), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    logActivity(
      GetAccountsUrl(UserData!.id.toString()!),
      "GET",
      response.status
    );
    if (!response.ok) {
      setErrorMessage(langText("error"));
      setLoading(false);
      return;
    }
    const data: AccountProps[] = await response.json();
    logActivity(GetAccountsUrl(UserData!.id.toString()!), "GET", data);
    data.sort((a, b) =>
      a.accountType === "CHECKING_BUSINESS" ||
      a.accountType === "SAVINGS_PERSONAL"
        ? 2
        : a.currency > b.currency
        ? 1
        : -1
    );

    dispatch(setAccounts(data));
    setLoading(false);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            color: "#44494B",
          }}
        >
          Transfero
        </h2>
        <br />
        <label htmlFor="name" className="transfer-form-label">
          Nga Llogaria:<span style={{ color: "red" }}>*</span>
        </label>
        <ChooseAccountButton
          filter={(account) => account.accountType.includes("CHECKING")}
          diff={Number(amount.replace(/,/g, ""))}
          allowSavings
        />

        <br />
        <label htmlFor="name" className="transfer-form-label">
          Ne Llogarine:<span style={{ color: "red" }}>*</span>
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginBottom: 20,
          }}
        >
          <div
            onClick={() => setMyAccountsTransfer(true)}
            style={{
              borderRadius: 20,
              backgroundColor: hexWithOpacity(
                "#5B6DEC",
                myAccountsTransfer ? 1 : 0.5
              ),
              color: "#FFF",
              padding: "10px 20px",
            }}
          >
            Llogaritë e mia
          </div>
          <div
            onClick={() => setMyAccountsTransfer(false)}
            style={{
              borderRadius: 20,
              backgroundColor: hexWithOpacity(
                "#5B6DEC",
                !myAccountsTransfer ? 1 : 0.5
              ),
              color: "#FFF",
              padding: "10px 20px",
            }}
          >
            Llogari tjetër
          </div>
        </div>
        {myAccountsTransfer ? (
          <ChooseAccountButton
            filter={(account) =>
              account.currency == "ALL" && account.id != chosenAcc?.id
            }
            diff={-Number(amount.replace(/,/g, ""))}
            thisAccount={targetAccount as AccountProps}
            setThisAccount={setTargetAccount}
            allowSavings
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Radio.Group
              style={{
                padding: "10px 0px",
              }}
              onChange={onAccountTypeChange}
              value={otherAccountType}
            >
              <Radio value={1}>Llogari në PAGO</Radio>
              <Radio value={2}>Llogari jashtë PAGO</Radio>
            </Radio.Group>

            {otherAccountType === 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <input
                  className="transfer-form-input"
                  placeholder="Numri i llogarisë"
                  onChange={(e) => {
                    setOtherPagoAcc({
                      id: Number(e.target.value),
                    });
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <input
                  placeholder="IBAN"
                  className="transfer-form-input"
                  onChange={(e) => setIBAN(e.target.value)}
                />
                <input
                  placeholder={langText("account_holder_name")}
                  className="transfer-form-input"
                  onChange={(e) => setAccountHolderName(e.target.value)}
                />
                <Radio.Group
                  style={{
                    padding: "10px 0px",
                  }}
                  onChange={onCurrencyChange}
                  value={currency}
                >
                  <Radio value={1}>{langText("ALL")}</Radio>
                  <Radio value={2}>{langText("EUR_long")}</Radio>
                </Radio.Group>
              </div>
            )}
          </div>
        )}
        <br />
        <label htmlFor="name" className="transfer-form-label">
          Vendos shumen qe doni te dergoni
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className="transfer-form-input"
          type="text"
          id="name"
          value={amount}
          onChange={(event) => {
            let number = event.target.value;
            if (
              isNaN(Number(number)) &&
              (amount.toString().includes(".") ? true : !number.endsWith("."))
            )
              return;
            if (number.startsWith("0") && number.length > 1)
              number = number.slice(1);
            setAmount(number);
          }}
        />
        <br />
        <label htmlFor="phoneNumber" className="transfer-form-label">
          Vendos nje mesazh:
        </label>
        <input
          className="transfer-form-input"
          type="text"
          id="phoneNumber"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <br />
        <div style={{ color: "red", textAlign: "center" }}>{errorMessage}</div>
        <button
          disabled={loading || Number(amount) <= 0}
          onClick={makeTransfer}
          className="transfer-btn"
          style={{
            opacity: loading || Number(amount) <= 0 ? 0.5 : 1,
          }}
        >
          {loading ? <LoadingSpinner /> : "Dërgo"}
        </button>
        <br />
      </div>
      {showQRCode && (
        <div
          style={{
            padding: "40px",
            gap: "10px",
            marginLeft: "-19000px",
            marginRight: "550px",
            marginTop: "140px",
            backgroundColor: "white",
            borderRadius: "25px",
            border: "1px solid #546BEA",
          }}
        >
          <QRCode
            value={qrCodeValue}
            size={367}
            bgColor="#FFFFFF"
            fgColor="#546BEA"
            level="H"
            // includeMargin={false}
            renderAs="svg"
          />
        </div>
      )}
      <Modal
        isVisible={success}
        setIsVisible={(bool) => {
          setSuccess(bool);
          setAmount("");
          setDescription("");
        }}
        title={"Transferta u krye me sukses"}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={400}
              width={400}
            />

            <button
              className="success-btn"
              onClick={() => {
                setSuccess(false);
                setAmount("");
                setDescription("");
              }}
              disabled={loading}
            >
              {langText("continue")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InnerTransfer;
