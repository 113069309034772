import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useUtilityService } from "../../hooks/useUtilityService";
import langText from "../../lang/i18n";
import {
  GetDigitalbBundles,
  GetOneBundles,
  GetProducts,
  GetTringBundles,
  UtilityInvoices,
} from "../../constants/endpoints";
import UtilityAvailabilityView from "./UtilityAvailabilityView";
import ChooseAccountButton from "../../components/ChooseAccountButton";
import { Contract } from "../../redux/general-slice";
import RegisterBillModal from "./RegisterBillModal";
import BundleItem from "../../components/BundleItem";
import "./SearchScreen.css";
import LoadingSpinner from "../../components/LoadingIndicator";
import UtilityPaidBill from "../../components/UtilityPaidBill";

export default function SearchScreen(props: any) {
  const { SelectedColor, Theme } = useAppSelector((state) => state.theme);

  const { chosenAcc, UserToken, PersonId, UserData } = useAppSelector(
    (state) => state.user
  );

  const { contracts } = useAppSelector((state) => state.general);
  const { provider, service, phoneNo } = props;
  const [myBundles, setMyBundles] = useState([]);
  const [selectedContract, setSelectedContract] = useState<Contract>();
  const [errorMessage, setErrorMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [firstTime, setfirstTime] = useState(true);
  const [paidBills, setPaidBills] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [newContract, setNewContract] = useState(new Map<string, string>());
  const {
    loading: contractsLoading,
    availability,
    saveContract,
    errorMsg,
  } = useUtilityService(service.operatorId ?? provider.operatorId, service);

  useEffect(() => {
    if (phoneNo && firstTime) {
      setSelectedContract({ phone_number: phoneNo } as any);
      return;
    }
    if (modalVisible || loading || contractsLoading) return;

    if (contracts.length === 0 && firstTime) {
      setLoading(false);
      setfirstTime(false);
      return;
    }

    if (
      !selectedContract ||
      Object.keys(selectedContract as Contract).length === 0
    )
      setSelectedContract(
        contracts.length > 0
          ? contracts.filter((item) => typeof item["vlera"] == "string")[0] ??
              {}
          : ({} as any)
      );
  }, [modalVisible, loading, contracts]);

  useEffect(() => {
    myBundles.map((bundle) => {
      if (bundle["Balanca"] !== undefined) {
        setBalance(bundle["Balanca"]);
      }
    });
  }, [myBundles]);

  useEffect(() => {
    if (!selectedContract) return;

    if (Object.keys(selectedContract as Contract).length === 0) {
      setErrorMessage(
        !langText(
          "bill_id_desc_" + (service.operatorId ?? provider.operatorId)
        ).startsWith("[missing")
          ? langText(
              "bill_id_desc_" + (service.operatorId ?? provider.operatorId)
            )
          : langText("bill_id_desc_")
      );
      setLoading(false);
      return;
    }
    setLoading(true);
    getBundles();
    getPaidBills();
  }, [selectedContract]);

  async function getBundles() {
    try {
      setMyBundles([]);
      let reqBody = requestBody();

      if (!reqBody) return;

      fetch(
        provider.short_name === "One"
          ? GetOneBundles()
          : provider.short_name === "Tring Tv"
          ? GetTringBundles()
          : provider.short_name === "Digitalb"
          ? GetDigitalbBundles()
          : GetProducts(),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          body: reqBody,
        }
      )
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            response.json().then((res) => {
              setMyBundles(Array.isArray(res?.Products) ? res?.Products : []);
            });
          } else if (response.status === 400) {
            setErrorMessage(langText("no_products_for_inputs"));
          } else if (response.status === 500) {
            setErrorMessage(langText("error"));
          } else {
            setErrorMessage(langText("no_products"));
          }
        })
        .catch((e) => {
          let err = Error(e);
          if (err.message === "TypeError: Network request failed") {
          }
        });
    } catch (e) {
      console.warn(e);
    }
  }

  function requestBody() {
    if ((service.operatorId ?? provider.operatorId) == "1") {
      let phone = selectedContract?.vlera;
      if (!phone) {
        setLoading(false);
        setErrorMessage(langText("no_products"));
        return;
      }

      return JSON.stringify({
        operatorId: service.operatorId ?? provider.operatorId,
        phoneNo: phone.startsWith("+")
          ? phone.substring(1, phone.length)
          : phone,
        customerId: PersonId,
        token: UserToken,
      });
    }
    // else if ((service.operatorId ?? provider.operatorId) == "43") {
    //   return JSON.stringify({
    //     msisdn: getFullNumber(selectedContract.vlera),
    //   });
    // }
    else if (
      (service.operatorId ?? provider.operatorId) == "18" ||
      (service.operatorId ?? provider.operatorId) == "19"
    ) {
      const selectedContractFirstKey = Object.keys(
        selectedContract as any
      )[0] as any;
      return JSON.stringify({
        phoneNo:
          selectedContract?.vlera != undefined
            ? selectedContract.vlera
            : selectedContract?.[
                selectedContractFirstKey as keyof typeof selectedContract
              ],
        operatorId: service.operatorId ?? provider.operatorId,
        token: UserToken,
        customerId: UserData?.id,
      });
    }
  }

  function getPaidBills() {
    try {
      setPaidBills([]);

      fetch(
        UtilityInvoices(
          chosenAcc?.id + "/" + (service.operatorId ?? provider.operatorId)
        ),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          // body: JSON.stringify({
          //   token: UserToken,
          // }),
        }
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((res) => {
            if (Array.isArray(res.Invoices)) {
              setPaidBills(res.Invoices);
            }
          });
        }
      });
    } catch (e) {}
  }

  return (
    <div>
      <div style={{ margin: 20 }}>
        <UtilityAvailabilityView
          name={langText(provider.short_name)}
          url={availability?.url}
        />
        <ChooseAccountButton
          filter={(account) =>
            service.currency ? account.currency === service.currency : true
          }
        />

        {(!service.maxContractLength ||
          contracts.length < service.maxContractLength) &&
          (!addNew ? (
            <div>
              <button
                className="add-contract-btn"
                onClick={async () => {
                  setAddNew(true);
                }}
              >
                {langText(
                  "add_new_" + (service.operatorId ?? provider.operatorId)
                )}
              </button>
            </div>
          ) : (
            <div>
              <div>
                {service.fields.map((field: any, index: any) => {
                  return (
                    <div key={field.label}>
                      <input
                        className="contract-fields"
                        type={field.type}
                        onChange={(e) => {
                          setHasError(false);
                          let newMap = new Map<string, string>();
                          newContract?.forEach((value, key) => {
                            newMap.set(key, value);
                          });
                          newMap.set(field.label, e.target.value);
                          setNewContract(newMap);
                        }}
                        value={newContract?.get(field.label) ?? ""}
                        placeholder={field.placeholder}
                        autoCapitalize={field.autoCapitalize}
                      />
                    </div>
                  );
                })}
              </div>
              <button
                className="add-contract-btn"
                onClick={async () => {
                  const obj = await saveContract(newContract);
                  if (!errorMsg && !!obj) {
                    setNewContract(new Map<string, string>());
                    setSelectedContract(obj);
                  }
                }}
              >
                {langText(
                  "add_new_" + (service.operatorId ?? provider.operatorId)
                )}
              </button>

              <div
                style={{
                  textAlign: "center",
                  color: "red",
                }}
              >
                {errorMsg}
              </div>
            </div>
          ))}
      </div>

      {!contractsLoading ? (
        <div>
          {selectedContract &&
            Object.keys(selectedContract as Contract).length !== 0 && (
              <div
                style={{ margin: 20, marginTop: 10 }}
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <div
                  style={{
                    borderBottomColor: "#DCDCDC",
                    backgroundColor: SelectedColor,
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  {Object.keys(selectedContract)
                    .filter((key) =>
                      Object.keys(selectedContract).includes("id")
                        ? key === "vlera"
                        : true
                    )
                    .map((key, index) => {
                      if (typeof selectedContract[key] == "object") {
                        return Object.keys(selectedContract[key]).map(
                          (key2, index2) => {
                            return (
                              <div
                                key={index2}
                                style={{
                                  fontSize: 18,
                                  color: "white",
                                }}
                              >
                                {langText(key2) +
                                  ": " +
                                  selectedContract[key][key2]}
                              </div>
                            );
                          }
                        );
                      }
                      return (
                        <div
                          key={index}
                          style={{
                            fontSize: 18,
                            color: "white",
                          }}
                        >
                          {(key === "vlera" ? "" : langText(key) + ": ") +
                            selectedContract[key]}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          {loading ? (
            <LoadingSpinner />
          ) : myBundles.length > 0 ? (
            <div>
              {myBundles.map((bundle: any, index) => {
                if (bundle["Balanca"] !== undefined) {
                  return;
                }
                if (
                  bundle["ActiveProducts"] ||
                  bundle["EligiblePromotions"] ||
                  (bundle["EligibleProducts"] &&
                    bundle["EligibleProducts"]?.length === 0) ||
                  (bundle["CampaignProducts"] &&
                    bundle["CampaignProducts"]?.length === 0)
                ) {
                  return;
                }
                return (
                  <BundleItem
                    index={index}
                    bundle={bundle}
                    contract={selectedContract}
                    provider={provider}
                    balance={balance}
                    operatorId={service.operatorId ?? provider.operatorId}
                  />
                );
              })}
            </div>
          ) : (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              <div
                style={{
                  fontFamily: "Roboto-Regular",
                  fontSize: 20,
                  alignItems: "center",
                  marginTop: 40,
                  textAlign: "center",
                  color: `${Theme.textColor}`,
                }}
              >
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div>
          <LoadingSpinner />
        </div>
      )}

      <RegisterBillModal
        modalVisible={modalVisible}
        onBackdropPress={() => {
          setModalVisible(false);
        }}
        setShouldReload={() => {
          setModalVisible(false);
        }}
        service={service}
        provider={provider.short_name}
        selected={selectedContract}
        filter={(item: any) => typeof item["vlera"] == "string"}
        selectContract={(contract: any) => setSelectedContract(contract)}
        operatorId={service.operatorId ?? provider.operatorId}
      />
      {paidBills.length > 0 && (
        <div>
          <div style={{ padding: 20 }}>
            <div style={{ fontSize: 20, fontFamily: "Roboto-Medium" }}>
              {langText("last_transactions")}
            </div>
            {paidBills.map((paidBill: any) => (
              <UtilityPaidBill key={paidBill.id} item={paidBill} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
