import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./reduxHooks";
import { logActivity } from "./functions";
import { ServiceProps } from "../constants/Utilities";
import { Contract, setContracts } from "../redux/general-slice";
import { OperatorEndpoint } from "../constants/endpoints";
import langText from "../lang/i18n";

export const unwantedOperators = [""];

export type ContractBill = {
  contract:
    | Contract
    | {
        value: string;
        onPress: () => void;
      };
  bills: any[];
  loading: boolean;
};

export const DefaultContractBills: ContractBill[] = [];

export const useUtilityService = (
  operatorId: string,
  service?: ServiceProps,
  modal?: boolean
) => {
  const { PersonId, UserToken, chosenAcc, UserData } = useAppSelector(
    (state) => state.user
  );
  const { availability, contracts } = useAppSelector((state) => state.general);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(!modal);
  const [errorMsg, setErrorMsg] = useState("");
  const availabilityStatus = useMemo(
    () => availability.get(parseInt(operatorId)),
    [availability]
  );

  async function getContracts() {
    if (!service) return;
    setLoading(true);
    setErrorMsg("");

    const response = await fetch(
      OperatorEndpoint() +
        operatorId +
        "/" +
        (chosenAcc?.accountHolder.id ?? PersonId),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
      }
    );

    if (!response.ok || response.status == 204) {
      dispatch(setContracts([]));
      return;
    }
    let data = await response.json();

    data.forEach((item: { vlera: string | any }) => {
      if (!item.vlera?.includes(":") || !item.vlera?.includes("/")) return item;
      const objList: string[] = item.vlera.split("/");
      item.vlera = objList.reduce((o, obj) => {
        const [key, value] = obj.split(":");
        return Object.assign(o, {
          [key]: value,
        });
      }, {});
    });
    dispatch(setContracts(data));
    setLoading(false);
  }

  async function saveContract(
    userInputs: Map<string, string>,
    noUpdate?: boolean
  ) {
    try {
      setLoading(true);
      setErrorMsg("");
      logActivity("userInputs", userInputs);
      let obj = new Map<string, string>();
      service?.fields?.map((field, index) => {
        if (!userInputs.get(field.label) && field.required)
          throw Error("fill_all_error");
        if (
          operatorId === "18" &&
          field.label === "Smart Card" &&
          userInputs.get(field.label)?.length == 12
        )
          obj.set(field.label, "0" + userInputs.get(field.label));
        else obj.set(field.label, userInputs.get(field.label) ?? "");
      });

      //get list of keys of the map
      const keys = Array.from(obj.keys());
      logActivity("keys", keys, keys.length, keys.length > 1);

      let vlera =
        keys.length > 1
          ? Array.from(obj.keys())
              .map((key) => key + ":" + obj.get(key))
              .join("/")
          : keys.length > 0
          ? obj.get(keys[0]) ?? ""
          : "";

      if (operatorId == "31" || operatorId == "32") {
        vlera = vlera.padStart(6, "0");
      }

      const body = JSON.stringify({
        operatorId: operatorId,
        customerId: UserData!.id,
        vlera: vlera,
        doNotify: 1,
      });

      const response = await fetch(OperatorEndpoint(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
        body: body,
      });

      setLoading(false);

      let resData = await response.json();
      if (!response.ok) {
        if (resData?.message?.includes("must be unique"))
          throw Error("unique_value");
        throw Error("error");
      }
      if (!noUpdate) getContracts();
      return resData;
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(
        langText(error?.message).startsWith("[missing")
          ? langText("error")
          : langText(error?.message ?? "error")
      );
    }
  }

  async function deleteContract(contract: any) {
    setLoading(true);
    setErrorMsg("");

    const response = await fetch(OperatorEndpoint(contract.id), {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    setLoading(false);

    if (!response.ok) {
      setErrorMsg(langText("error"));
      return false;
    }
    getContracts();
  }

  useEffect(() => {
    if (modal) return;
    logActivity("Changed operator or service", operatorId, service);
    dispatch(setContracts([]));
    getContracts();
    return () => {
      dispatch(setContracts([]));
    };
  }, [operatorId, service]);

  return {
    availability: availabilityStatus,
    loading,
    errorMsg,
    saveContract,
    deleteContract,
  };
};
