import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { AccountProps, setChosenAcc } from "../redux/user-slice";
import { formatMoney } from "../functions";
import { hexWithOpacity } from "../hooks/functions";
import Modal from "./Modal";
import langText from "../lang/i18n";

type Props = {
  diff?: number;
  allowSavings?: boolean;
  filter?: (account: AccountProps) => boolean;
  thisAccount?: AccountProps;
  setThisAccount?: (account: AccountProps) => void;
  style?: React.CSSProperties;
};

export default function ChooseAccountButton({
  diff,
  allowSavings = false,
  filter = (account) => true,
  thisAccount,
  setThisAccount,
  style = {},
}: Props) {
  const { allAccounts, chosenAcc } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!chosenAcc) return;
    if (
      !thisAccount &&
      !allowSavings &&
      allAccounts?.filter(filter).find((acc) => acc.id === chosenAcc?.id) ===
        undefined
    ) {
      dispatch(setChosenAcc(allAccounts.filter(filter)[0]));
    }
  }, []);

  const handleAccountClick = (account: AccountProps) => {
    if (!!setThisAccount) setThisAccount(account);
    else dispatch(setChosenAcc(account));
    setIsModalOpen(false);
  };

  const renderAmountWithDiff = () => {
    if (!!diff) {
      return (
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          {formatMoney((thisAccount ?? chosenAcc)?.balance.amount.value ?? 0) +
            " " +
            langText((thisAccount ?? chosenAcc)?.currency)}{" "}
          <span className="arrow">➜</span>{" "}
          {formatMoney(
            ((thisAccount ?? chosenAcc)?.balance.amount.value ?? 0) - diff
          ) +
            " " +
            langText((thisAccount ?? chosenAcc)?.currency)}
        </span>
      );
    }
    return (
      <span>
        {formatMoney((thisAccount ?? chosenAcc)?.balance.amount.value ?? 0) +
          " " +
          langText((thisAccount ?? chosenAcc)?.currency)}
      </span>
    );
  };

  const renderAccountModal = () => {
    return (
      <Modal
        isVisible={isModalOpen}
        setIsVisible={setIsModalOpen}
        title={langText("choose_account")}
      >
        <div>
          {allAccounts
            .filter(filter)
            .filter(
              (item) =>
                item.accountHolder?.kycVerified !== false &&
                (allowSavings ? true : item.accountType.includes("CHECKING"))
            )
            .map((account) => (
              <div
                key={account.id}
                style={{
                  padding: 10,
                  border: "1px solid #546BEA",
                  borderRadius: 10,
                  backgroundColor:
                    (thisAccount ?? chosenAcc)?.id === account.id
                      ? "#546BEA"
                      : "white",
                  marginBottom: 10,
                }}
                onClick={() => handleAccountClick(account)}
              >
                <h2
                  style={{
                    color:
                      (thisAccount ?? chosenAcc)?.id === account.id
                        ? "white"
                        : "black",
                  }}
                >
                  {langText("balance") +
                    " " +
                    langText(
                      account.currency === "EUR" ? "EUR_long" : account.currency
                    ) +
                    " " +
                    (!!account.name
                      ? account.name
                      : account.accountHolder.name)}
                </h2>
                <h1
                  style={{
                    color:
                      (thisAccount ?? chosenAcc)?.id === account.id
                        ? "white"
                        : "black",
                    fontSize: 24,
                  }}
                >
                  {formatMoney(account.balance.amount.value)}
                </h1>
              </div>
            ))}
        </div>
      </Modal>
    );
  };

  return (
    <div
      style={{
        padding: 10,
        border: "1px solid #546BEA",
        borderRadius: "10px",
        boxShadow: "0px 0px 20px " + hexWithOpacity("#546BEA", 0.3),
        backgroundColor: "white",
        ...style,
      }}
      onClick={() => setIsModalOpen(true)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: !!diff ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {!!(thisAccount ?? chosenAcc)?.name
            ? (thisAccount ?? chosenAcc)?.name
            : (thisAccount ?? chosenAcc)?.accountHolder.name}
        </h2>
        <p>{renderAmountWithDiff()}</p>
      </div>
      {renderAccountModal()}
    </div>
  );
}
