import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import arrow from "../assets/icons/Shape.svg";
import mastercard from "../assets/icons/mastercard.svg";
import visa from "../assets/icons/visa.svg";
import tick from "../assets/icons/tick.svg";
import add from "../assets/icons/add-square.svg";
import user from "../assets/icons/user1.svg";
import user2 from "../assets/icons/profile.svg";
import "./DepositScreen.css";
import { handleCardNumber, logActivity } from "../hooks/functions";
import langText from "../lang/i18n";
import { formatMoney } from "../functions";
import {
  EmailBankDetails,
  MasterCardEcomerceEndpoint,
  PaymentCards,
  PaymentMCCards,
  SearchTrasnfers,
  TransferMoney,
} from "../constants/endpoints";
import Modal from "../components/Modal";
import LoadingSpinner from "../components/LoadingIndicator";
import { useAppSelector } from "../hooks/reduxHooks";
import ChooseAccountButton from "../components/ChooseAccountButton";
import LinkWrapper from "../components/LinkWrapper";
import { logOutClearDB } from "../localDatabase/mainDB";
import { useNavigate } from "react-router-dom";

// type PaymentCard = {
//   id: number;
//   expirationMonth: number;
//   lastFourDigits: number;
//   cardType: "VISA" | "MASTER_CARD";
//   customer: { id: string };
//   expirationYear: number;
//   cardHolderName: string;
//   creditCardNumberHash: string;
//   cvv: string;
//   cRefID?: string;
// };

// const [cards, setCards] = useState<PaymentCard[]>([]);

// const card = cards.find((item) => item.id === selectedCard);

// const reqBody = {
//   fundingSource: !card ? 1 : card.id,
//   additional_information: "Adding funds with Credit Card",
//   transferType: "PAYMENT_CARD",
//   account: chosenAcc?.id,
//   currencyCode: chosenAcc?.currency,
//   amount: moneyValue.replace(/,/g, ""),
// };

const bankAccounts = [
  {
    id: 13147,
    bank: "FIBANK",
    name: "RUBICON SH.A",
    accountNumber: "-", //"000017593700",
    iban: handleCardNumber("AL3521511031ALL1000017593702"),
    swift: "FINVALTR",
    currency: "ALL",
  },
  {
    id: 13152,
    bank: "FIBANK",
    name: "RUBICON SH.A",
    accountNumber: "-",
    iban: handleCardNumber("AL8021511031EUR1000017593700"),
    swift: "FINVALTR",
    currency: "EUR",
  },
  {
    id: 13146,
    bank: "TIRANA BANK",
    name: "RUBICON SH.A",
    accountNumber: "0140321961100",
    iban: handleCardNumber("AL11206110420000140321961100"),
    swift: "TIRBALTR",
    currency: "ALL",
  },
  {
    id: 13153,
    bank: "TIRANA BANK",
    name: "RUBICON SH.A",
    accountNumber: "-",
    iban: handleCardNumber("AL81206110420000140321961101"),
    swift: "TIRBALTR",
    currency: "EUR",
  },
  {
    id: 13148,
    bank: "BKT",
    name: "RUBICON SH.A",
    accountNumber: "422009918CLPRCLALLWK",
    iban: handleCardNumber("AL2720511227009918CLPRCLALLW"),
    swift: "NCBAALTX",
    currency: "ALL",
  },
  {
    id: 13151,
    bank: "BKT",
    name: "RUBICON SH.A",
    accountNumber: "-",
    iban: handleCardNumber("AL8620511227009918CLPRCFEURP"),
    swift: "NCBAALTX",
    currency: "EUR",
  },
  {
    id: 13149,
    bank: "OTP",
    name: "RUBICON SH.A",
    accountNumber: "00002162133",
    iban: handleCardNumber("AL29902112090201230218851538"),
    swift: "OTPVHUHBXXX",
    currency: "ALL",
  },
  // {
  //   id: 13145,
  //   bank: "CREDINS",
  //   name: "RUBICON SH.A",
  //   accountNumber: "00001591728",
  //   iban: handleCardNumber("AL22212110090000000001591728"),
  //   swift: "CDISALTRXXX",
  //   currency: "ALL",
  // },
  {
    id: 13150,
    bank: "Intesa Sanpaolo",
    name: "RUBICON SH.A",
    accountNumber: "24047135302",
    iban: handleCardNumber("AL14208112590000024047135302"),
    swift: "USALALTR XXX",
    currency: "ALL",
  },
];

type PaymentCard = {
  id: number;
  expirationMonth: number;
  lastFourDigits: number;
  cardType: "VISA" | "MASTER_CARD";
  customer: { id: string };
  expirationYear: number;
  cardHolderName: string;
  creditCardNumberHash: string;
  cvv: string;
  cRefID?: string;
};

export default function DepositScreen() {
  const { UserToken, UserData, chosenAcc, PersonId } = useAppSelector(
    (state) => state.user
  );
  const [selectedBank, setSelectedBank] = useState(bankAccounts[0]);
  const [hasChosenAccount, setHasChosenAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [moneyValue, setMoneyValue] = useState("2,000");
  const [transferAmount, setTransferAmount] = useState("2,000");
  const [modalVisible, setModalVisible] = useState(false);
  const [email, setEmail] = useState(
    !UserData?.email?.includes("thisisnotarealserver.com")
      ? UserData!.email
      : ""
  );
  const [cards, setCards] = useState<PaymentCard[]>([]);
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const [casysModal, setCasysModal] = useState(false);
  const [firstMonthlyTransaction, setFirstMonthlyTransaction] = useState(false);
  const [params, setParams] = useState("");
  const [percentageModal, setPercentageModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    getuserCards();
    checkFirstTransaction();
  }, []);

  useEffect(() => {
    setTransferAmount(parseFloat(moneyValue.replace(/,/g, "")).toFixed(2));
  }, [moneyValue]);

  const handleMoneyValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rawValue = event.target.value;

    // Remove existing commas from the raw value
    const cleanedValue = rawValue.replace(/,/g, "");

    // Format the value with commas
    const formattedValue = cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    setMoneyValue(formattedValue);
  };

  const isEmailValid = () => {
    let regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const sendToEmail = () => {
    logActivity("Sending to email");
    if (!isEmailValid()) {
      setErrorMsg(langText("fill_all_error"));
      return;
    }
    setLoading(true);
    fetch(EmailBankDetails(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer 7a9544c8-1db4-4bce-8ea6-b5d59c5d2a66",
      },
      body: JSON.stringify({
        email: email,
        bankName: selectedBank.bank,
        accountName: selectedBank.name,
        iban: selectedBank.iban,
        bankSwift: selectedBank.swift,
        currency: selectedBank.currency,
        reference: "" + chosenAcc?.id,
      }),
    })
      .then((res) => {
        setLoading(false);
        logActivity("EmailBankDetails " + res.status);
        if (res.status == 200) {
          setModalVisible(false);
          setSuccess(true);
        } else {
          setErrorMsg(langText("error"));

          res.json().then((res) => {
            logActivity(res);
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        let err = Error(e);
        logActivity(err);
        setErrorMsg(langText("error"));
      });
  };

  const getuserCards = async () => {
    let cardsNr = 0;
    let response;
    let resData;
    let responseMC;
    let resMCData;
    let finalList = [];
    try {
      response = await fetch(PaymentCards(), {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
      });
      logActivity(PaymentCards(), response.status);
      if (response.ok) {
        resData = await response.json();
        logActivity(resData);
      }

      responseMC = await fetch(PaymentMCCards(), {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + UserToken,
        },
      });
      logActivity(PaymentMCCards(), responseMC.status);
      if (responseMC.ok) {
        resMCData = await responseMC.json();
        logActivity(resMCData);
      }
    } catch (e) {
    } finally {
      logActivity("resData", resData);
      logActivity("resMCData", resMCData);
      if (!!resData) finalList.push(...resData);
      if (!!resMCData) finalList.push(...resMCData);
      setCards(finalList);
      if (finalList.length === 1) {
        setSelectedCard(finalList[0].id);
      }

      cardsNr = (resData?.length ?? 0) + (resMCData?.length ?? 0);
      return cardsNr;
    }
  };

  async function checkFirstTransaction() {
    const today = new Date();
    const response = await fetch(SearchTrasnfers(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        from: today.toISOString(),
        to: today.toISOString(),
        account: chosenAcc?.id,
        type: "CPAY",
      }),
    });
    logActivity(SearchTrasnfers(), response.status);
    const resData = response.status == 200 ? await response.json() : undefined;
    logActivity(
      SearchTrasnfers(),
      resData,
      !resData || !Array.isArray(resData) || resData.length === 0
    );
    setFirstMonthlyTransaction(
      !resData || !Array.isArray(resData) || resData.length === 0
    );
  }

  const makeTransfer = () => {
    if (!firstMonthlyTransaction) {
      logActivity(
        "Changing amount from ",
        transferAmount,
        "to",
        (parseFloat(moneyValue.replace(/,/g, "")) / 0.97).toFixed(2)
      );
      setTransferAmount(
        (parseFloat(moneyValue.replace(/,/g, "")) / 0.97).toFixed(2)
      );
      setFirstMonthlyTransaction(true);
      setPercentageModal(true);
      return;
    }

    // if (cards.length === 0 && !newCardModal) {
    //   setNewCardModal(true);
    //   return;
    // }
    const card = cards.find((item) => item.id === selectedCard);

    logActivity(
      "sending " +
        UserToken +
        " " +
        card?.id +
        " " +
        chosenAcc?.id +
        " " +
        chosenAcc?.currency +
        " " +
        transferAmount
    );

    const reqBody = {
      fundingSource: !card ? 1 : card.id,
      additional_information: "Adding funds with Credit Card",
      transferType: "PAYMENT_CARD",
      account: chosenAcc?.id,
      currencyCode: chosenAcc?.currency,
      amount: transferAmount,
    };

    logActivity(reqBody);

    fetch(TransferMoney(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        logActivity("TransferMoney " + res.status);
        if (res.status === 201) {
          res.json().then((data) => {
            logActivity(data);
            setParams(
              `?t=${data.id}&amount=${transferAmount}&email=${
                UserData?.email ?? ""
              }&tel=${UserData?.mobileNumber ?? ""}&address1=${
                UserData?.address?.line1 ?? ""
              }&city=${UserData?.address?.city ?? ""}&zipcode=${
                UserData?.address?.postalCode ?? ""
              }${
                "&cRef=" + (!!card?.cRefID ? card.cRefID : "-2")
              }&details1=${langText("rbcn_deposit")}`
            );
            setCasysModal(true);
          });
        } else {
          setErrorMsg(langText("transfer_failed"));
          res.json().then((res) => {
            logActivity(res);
          });
        }
      })
      .catch((e) => {
        let err = Error(e);
        if (err.message === "TypeError: Network request failed") {
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Sidebar />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          marginLeft: "7rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 60,
          }}
        >
          <p
            className="depozito"
            style={{
              color: "white",
              marginLeft: "40px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "40px",
              lineHeight: "60px",
              marginTop: "20px",
            }}
          >
            Depozito
          </p>
          <p
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              fontSize: "24px",
              lineHeight: "36px",
              color: "#FFFFFF",
              marginLeft: "40px",
            }}
          >
            Zgjidhni një nga metodat e depozitimit për të shtuar para ne
            llogarinë tuaj Pago..
          </p>
        </div>

        <div className="columns-container">
          <div className="column">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <img src={user2} style={{}} />
              <p
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  color: "#AE49FF",
                }}
              >
                Shto para përmes transfertës bankare{" "}
              </p>
            </div>
            <div>
              <div>
                <select
                  className="bank-button"
                  value={selectedBank.id}
                  onChange={(e) => {
                    setSelectedBank(
                      bankAccounts.find(
                        (bank) => bank.id === Number(e.target.value)
                      )!
                    );
                  }}
                  style={{
                    padding: 20,
                    marginBottom: "20px",
                    border: "1px solid grey",
                    fontSize: 16,
                  }}
                >
                  {bankAccounts.map((acc) => {
                    return (
                      <option value={acc.id}>
                        {acc.bank +
                          " - " +
                          langText(
                            acc.currency === "EUR" ? "EUR_long" : acc.currency
                          )}
                      </option>
                    );
                  })}
                </select>

                <button className="bank-two">
                  <p className="emri-llog">Emri i llogarisë</p>
                  {selectedBank.name}
                  <p className="emri-llog">IBAN</p>
                  {selectedBank.iban}
                  <p className="emri-llog">Numri i llogarisë</p>
                  {selectedBank.id}
                  <p className="emri-llog">Swift</p>
                  {selectedBank.swift}
                  <p className="emri-llog">Përshkrimi</p>
                  {chosenAcc?.id}
                </button>
              </div>

              <button
                style={{
                  marginTop: 20,
                }}
                className="continue"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                Dërgo të dhënat në email
              </button>
            </div>
          </div>
          <div className="column">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <img src={user} style={{}} />
              <p
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "24px",
                  lineHeight: "36px",
                  color: "#546BEA",
                }}
              >
                Shto para përmes kartës
              </p>
            </div>
            <div style={{ textAlign: "left" }}>
              <div style={{ marginBottom: 20 }}>
                <ChooseAccountButton
                  diff={-Number(moneyValue.replace(/,/g, ""))}
                />
              </div>
              <h5 className="vendos-shm" style={{ marginBottom: 20 }}>
                Vendos shumën që doni të dërgoni*{" "}
              </h5>
              <input
                className="textbox"
                style={{
                  fontFamily: "Poppins",
                  paddingLeft: "20px",
                  fontSize: "18px",
                  marginBottom: 20,
                }}
                value={moneyValue}
                onChange={handleMoneyValueChange}
              />
              <h5 className="vendos-shm" style={{ marginBottom: 20 }}>
                Zgjidhni kartën*
              </h5>
              {cards.map((card) => (
                <button
                  className="card-item"
                  style={{
                    borderColor:
                      selectedCard === card.id ? "#72B5A8" : "#E5E5E5",
                  }}
                  onClick={() => setSelectedCard(card.id)}
                >
                  <img
                    src={card.cardType === "MASTER_CARD" ? mastercard : visa}
                    style={{ width: 50, height: 50 }}
                  />
                  <p>
                    {card.lastFourDigits != null
                      ? "**** **** **** " +
                        card.lastFourDigits.toString().padStart(4, "0")
                      : "**** **** **** ****"}
                  </p>
                  {selectedCard === card.id && <img src={tick} />}
                </button>
              ))}
              <button
                className="add-new"
                onClick={
                  () => setCasysModal(true)
                  //makeTransfer
                }
                disabled
              >
                <img src={add} /> shto kartë të re
              </button>
              <button className="continue" onClick={makeTransfer} disabled>
                Vazhdo
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal isVisible={modalVisible} setIsVisible={setModalVisible} title={""}>
        <div>
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              lineHeight: "36px",
              textAlign: "center",
              color: "#000000",
              padding: 20,
            }}
          >
            Vendosni adresen tuaj te emailit{" "}
            <form
              style={{
                alignContent: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <label style={{ padding: "20px" }}>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{
                    height: 55,
                    width: 300,
                    padding: 15,
                    fontSize: "20px",
                  }}
                />
              </label>
            </form>
          </div>
          <div
            style={{
              color: "red",
              textAlign: "center",
              fontSize: "20px",
              marginBottom: 50,
              marginTop: -20,
            }}
          >
            {errorMsg}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <button
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // padding: "12px 145px",
                gap: "10px",
                width: "315.56px",
                height: "66px",
                left: "0px",
                top: "120px",
                background: "#FFFFFF",
                border: "1px solid #546BEA",
                borderRadius: "10px",
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '22px',
                color: '#546BEA',
                cursor: "pointer",
              }}
            >

              Mbyll
            </button> */}
            <button
              onClick={sendToEmail}
              disabled={loading}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                // padding: '12px 145px',
                gap: "10px",
                width: "315.56px",
                height: "66px",
                left: "326.44px",
                top: "120px",
                background: "#546BEA",
                borderRadius: "10px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "14px",
                lineHeight: "22px",
                cursor: "pointer",
                color: "white",
                marginTop: "-30px",
                marginBottom: "30px",
                borderColor: "transparent",
                opacity: loading || !isEmailValid() ? 0.5 : 1,
              }}
            >
              {loading ? <LoadingSpinner /> : "Dergo ne Email"}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isVisible={success}
        setIsVisible={setSuccess}
        title={"Email u dergua me sukses"}
      >
        <div></div>
      </Modal>
      <LinkWrapper
        link={MasterCardEcomerceEndpoint() + params}
        show={casysModal}
        setShow={setCasysModal}
      />
    </div>
  );
}
