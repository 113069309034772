import {
  faChevronDown,
  faChevronUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./HomeScreen.css";
import langText from "../lang/i18n";
import { FaRegBell } from "react-icons/fa";
import { formatMoney } from "../functions";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logActivity } from "../hooks/functions";
import useNotifications from "../hooks/useNotifications";
import AccountDetailsModal from "../components/AccountDetailsModal";
import { GetHomeScreenData } from "../constants/endpoints";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import BookingDataTable from "../components/BookingDataTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { logOutClearDB, setIsLoggedIn } from "../localDatabase/mainDB";
import { AccountProps, setAccounts, setChosenAcc } from "../redux/user-slice";

const HomeScreen = () => {
  const { UserToken, UserData, chosenAcc, allAccounts } = useAppSelector(
    (state) => state.user
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hasPermissions, askForPermissions, revokePermissions } =
    useNotifications();
  const [bookings, setBookings] = useState<any[]>([]);
  const [shownBookings, setShownBookings] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [records, setRecords] = useState(20);
  const [showAllAccounts, setShowAllAccounts] = useState(false);
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [accountDetails, setAccountDetails] = useState<AccountProps>(
    {} as AccountProps
  );

  useEffect(() => {
    logActivity(records);
    setShownBookings(bookings.slice(0, records));
  }, [records]);

  useEffect(() => {
    if (!bookings) return;
    setRecords(20);
    setHasMore(true);
    setShownBookings(bookings.slice(0, 20));
  }, [bookings]);

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
    getData();
  }, []);

  const getData = async () => {
    const response = await fetch(GetHomeScreenData(UserData!.id.toString()), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
        "Web-Client": "Q29taW5nRnJvbVdlYg==",
      },
    });
    logActivity("GetHomeScreenData", response.status);

    if (response.status === 401) {
      logOutClearDB();
      setIsLoggedIn(false);
      navigate("/login");
      return;
    }

    if (!response.ok) return;
    const data = await response.json();
    if (!data || !data.accounts) return;
    logActivity(data);
    const order: Record<string, number> = {
      CHECKING_PERSONAL: 1,
      CHECKING_BUSINESS: 2,
      SAVINGS_PERSONAL: 3,
    };
    data.accounts.sort((a: any, b: any) => {
      return order[a.accountType] - order[b.accountType];
    });

    const filteredOrder: Record<string, number> = {
      ALL: 1,
      EUR: 2,
    };

    data.accounts.sort((a: any, b: any) => {
      return filteredOrder[a.currency] - filteredOrder[b.currency];
    });

    dispatch(setAccounts(data.accounts));

    const foundAccount = data.accounts.find(
      (acc: AccountProps) => acc.id === chosenAcc?.id
    );
    logActivity("foundAccount", foundAccount);
    dispatch(setChosenAcc(foundAccount ?? data.accounts[0]));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Sidebar />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#F5F5F5",
          marginLeft: "7rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#5B6DEC",
            padding: 30,
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              margin: "0px 20px",
              paddingTop: "20px",
            }}
          >
            <span className="welcome-text">
              Mirë se erdhe,
              <span style={{ fontWeight: 200 }}>{" " + UserData!.name}</span>
            </span>
          </div>
          <div
            onClick={() => {
              if (!hasPermissions) {
                askForPermissions();
              } else {
                revokePermissions();
              }
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                color: "white",
                fontSize: 18,
                padding: 5,
                border: "none",
              }}
            >
              {!hasPermissions && "Lejo Njoftimet"}
              {hasPermissions && "Hiq Njoftimet"}
            </div>
            <FaRegBell color="#ffffff" size={20}></FaRegBell>
          </div>

          <div
            style={{
              fontSize: 30,
              gridTemplateColumns: "repeat(auto-fit, minmax(400px, 1fr))",
              display: "grid",
              height: showAllAccounts ? "auto" : 200,
              overflow: "hidden",
            }}
          >
            {!!allAccounts &&
              allAccounts.map((account) => {
                return (
                  <div
                    key={account.id}
                    className={`account-card`}
                    style={{
                      opacity: account.id !== chosenAcc?.id ? 0.3 : 1,
                    }}
                    onClick={() => {
                      setBookings([]);
                      dispatch(setChosenAcc(account));
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2 className="account-card-balance-text">
                        {!!account.name
                          ? account.name
                          : account.accountType === "CHECKING_BUSINESS"
                          ? account.accountHolder.name
                          : "Balanca" +
                            " " +
                            (account.currency === "ALL" ? "Lekë" : "Euro")}
                      </h2>
                      <div
                        style={{
                          width: 50,
                          alignSelf: "flex-end",
                        }}
                      >
                        <FontAwesomeIcon
                          //@ts-ignore
                          icon={faFile}
                          color="white"
                          onClick={() => navigate("/extract")}
                          size="xs"
                          style={{ marginRight: "0.5em" }}
                        />
                        <FontAwesomeIcon
                          //@ts-ignore
                          icon={faInfoCircle}
                          color="white"
                          onClick={() => {
                            setShowAccountDetails(true);
                            setAccountDetails(account);
                          }}
                          size="xs"
                        />
                      </div>
                    </div>
                    <h2 className="account-card-amount">
                      {formatMoney(account.balance.amount.value) +
                        " " +
                        (account.currency === "ALL" ? "Lekë" : "Euro")}
                    </h2>
                  </div>
                );
              })}
            <AccountDetailsModal
              isVisible={showAccountDetails}
              setIsVisible={setShowAccountDetails}
              account={accountDetails}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                backgroundColor: "transparent",
                color: "white",
              }}
              onClick={() => setShowAllAccounts(!showAllAccounts)}
            >
              <p style={{ marginRight: 10 }}>Shiko të gjitha</p>
              {!showAllAccounts ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0px 30px",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              borderRadius: "25px",
              border: "1px #cccccc",
              margin: "20px",
              marginTop: "-30px",
              backgroundColor: "#FFF",
              padding: "35px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <h2
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#44494B",
                }}
              >
                {langText("transactions")}
              </h2>
            </div>
            <BookingDataTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
