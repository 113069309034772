import { useEffect } from "react";
import { useSelector } from "react-redux";
import BundleProductItem from "./BundleProductItem";
import langText from "../lang/i18n";

const BundleItem = ({
  index,
  bundle,
  navigation = {},
  balance,
  contract,
  operatorId,
  provider,
}) => {
  const { SelectedColor } = useSelector((state) => state.theme);

  useEffect(() => {}, []);

  let keys = Object.keys(bundle);
  if (keys.length === 0 || keys[0] === "Balanca") return null;
  return (
    <div key={index}>
      <div style={{ flex: 1 }}>
        <div
          style={{
            margin: 20,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              marginBottom: 10,
              fontSize: 20,
            }}
          >
            {langText(keys[0])}
          </div>
          <div
            style={{
              width: "100%",
              height: 5,
              borderRadius: 10,
              backgroundColor: SelectedColor,
            }}
          />
        </div>

        <div
          style={{
            marginTop: 0,
          }}
        >
          {bundle[keys[0]].map((product, index) => {
            return (
              <BundleProductItem
                key={index}
                index={index}
                product={product}
                provider={provider}
                navigation={navigation}
                balance={balance}
                contract={contract}
                operatorId={operatorId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BundleItem;
