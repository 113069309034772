import type { Dayjs } from "dayjs";
import type { CalendarProps } from "antd";
import { Calendar } from "antd";
import { useEffect, useState } from "react";
import {
  MTDailyTotals,
  MerchantTerminalCurrencies,
  MerchantTransByDate,
} from "../constants/endpoints";
import { useAppSelector } from "../hooks/reduxHooks";
import LoadingSpinner from "./LoadingIndicator";

import { formatMoney } from "../functions";

export default function MerchantTransactionsCalendar(props: any) {
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyTotals, setDailyTotals] = useState<any[]>();
  const [monthlyTotals, setMonthlyTotals] = useState<any[]>();
  const [terminalCurrencies, setTerminalCurrencies] = useState();

  const MONTHS = [
    "Janar",
    "Shkurt",
    "Mars",
    "Prill",
    "Maj",
    "Qershor",
    "Korrik",
    "Gusht",
    "Shtator",
    "Tetor",
    "Nëntor",
    "Dhjetor",
  ];

  const { UserToken } = useAppSelector((state) => state.user);

  useEffect(() => {
    getTerminalCurrencies();
    getMTDailyTotals();
  }, []);

  const getMTDailyTotals = async () => {
    const response = await fetch(MTDailyTotals(), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    if (!response.ok) return;
    const res = await response.json();
    setDailyTotals(res);
    groupSumByMonth(res);
  };

  const groupSumByMonth = (data: any) => {
    const groupedSum = {} as any;

    data?.forEach((item: any) => {
      const year = item.date.substring(0, 4);
      const month = item.date.substring(4, 6);
      const key = `${year}-${month}`;

      if (!groupedSum[key]) {
        groupedSum[key] = [];
      }
      if (!groupedSum[key][item.terminal]) {
        groupedSum[key][item.terminal] = 0;
      }

      groupedSum[key][item.terminal] += item.sum;
    });

    setMonthlyTotals(groupedSum);
  };

  const getMerchantTransactions = async (
    from: string,
    to: string,
    terminal: string
  ) => {
    setLoading(true);
    const merchantTransactionUrl = MerchantTransByDate();
    const response = await fetch(merchantTransactionUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        from,
        to,
        terminal,
      }),
    });
    if (!response.ok) return;
    const transactionsList = await response.json();
    props.setTransactions({
      transactions: transactionsList,
      header: `Terminali: ${terminal},${
        from === to ? formatDate(from) : formatDate(from) + "-" + formatDate(to)
      }`,
    });
    setLoading(false);
  };

  const formatDate = (value: string) => {
    const year = value.substring(0, 4);
    const month = value.substring(4, 6);
    const date = value.substring(6, 8);
    return `${date} ${MONTHS[parseInt(month) - 1]} ${year}`;
  };

  const getListData = (value: Dayjs) => {
    let listData: any[] = [];

    dailyTotals?.forEach((day) => {
      const month =
        (value.get("month") + 1).toString().length === 1
          ? "0" + (value.get("month") + 1).toString()
          : (value.get("month") + 1).toString();

      const date =
        value.get("date").toString().length === 1
          ? "0" + value.get("date").toString()
          : value.get("date").toString();
      const cellDate = value.get("year").toString() + month + date;

      if (day.date === cellDate) {
        listData.push({
          terminal: `${day.terminal}`,
          sum: `${day.sum} ${day.currency}`,
        });
      }
    });
    return listData;
  };

  const getMonthData = (value: Dayjs) => {
    const year = value.get("year");
    const month =
      (value.get("month") + 1).toString().length === 1
        ? "0" + (value.get("month") + 1).toString()
        : (value.get("month") + 1).toString();
    return monthlyTotals?.[`${year}-${month}` as any];
  };

  const monthCellRender = (value: Dayjs) => {
    const monthData = getMonthData(value);
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <ul>
          {monthData &&
            Object.keys(monthData).map((terminal: any) => (
              <li>
                <button
                  style={{
                    boxShadow: "0.8px 0.8px 2px 0.05px #546BEAAA",
                    minWidth: "70%",
                    color: "#414141",
                    width: "fit-content",
                    backgroundColor: "#546BEA11",
                    cursor: "pointer",
                    margin: "0.5em 0em",
                    border: "none",
                    borderRadius: 4,
                    fontSize: 14,
                    padding: "6px",
                  }}
                  onClick={async () => {
                    const month =
                      (value.get("month") + 1).toString().length === 1
                        ? "0" + (value.get("month") + 1).toString()
                        : (value.get("month") + 1).toString();

                    const startDate =
                      value.get("year").toString() + month + "01";
                    const endDate = value.get("year").toString() + month + "31";
                    await getMerchantTransactions(startDate, endDate, terminal);
                  }}
                  key={terminal}
                >
                  {terminal +
                    ": " +
                    formatMoney(monthData[terminal]) +
                    " " +
                    terminalCurrencies?.[terminal]}
                </button>
              </li>
            ))}
        </ul>
      </div>
    );
  };

  const dateCellRender = (value: Dayjs) => {
    let listData = getListData(value);

    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <ul className="events">
          {listData.map((item) => (
            <button
              style={{
                boxShadow: "0.8px 0.8px 2px 0.05px #546BEAAA",
                minWidth: "70%",
                color: "#414141",
                width: "fit-content",
                backgroundColor: "#546BEA11",
                cursor: "pointer",
                margin: "0.5em 0em",
                border: "none",
                borderRadius: 4,
                fontSize: 14,
                padding: "6px",
              }}
              onClick={async () => {
                const month =
                  (value.get("month") + 1).toString().length === 1
                    ? "0" + (value.get("month") + 1).toString()
                    : (value.get("month") + 1).toString();

                const date =
                  value.get("date").toString().length === 1
                    ? "0" + value.get("date").toString()
                    : value.get("date").toString();

                const cellDate = value.get("year").toString() + month + date;
                await getMerchantTransactions(
                  cellDate,
                  cellDate,
                  item.terminal
                );
              }}
              key={item.content}
            >
              {item.terminal +
                ": " +
                formatMoney(item.sum) +
                " " +
                terminalCurrencies?.[item.terminal]}
            </button>
          ))}
        </ul>
      </div>
    );
  };

  const getTerminalCurrencies = async (params?: any) => {
    const response = await fetch(MerchantTerminalCurrencies(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    if (!response.ok) return;
    const terminalCurrencies = await response.json();
    const terminalInfo: any = {};
    terminalCurrencies.map((element: any) => {
      terminalInfo[element.terminal as any] = element.currency;
    });
    setTerminalCurrencies(terminalInfo);
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  return (
    <div
      style={{
        margin: "2em",
        padding: "1em",
        border: "1px solid #EDEDED",
        borderRadius: "2em",
      }}
    >
      {loading ? <LoadingSpinner /> : <Calendar cellRender={cellRender} />}
    </div>
  );
}
