import React, { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/reduxHooks";
import { getCurrentServer } from "../localDatabase/mainDB";
import { useNavigate } from "react-router-dom";
import "./ContactList.css";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, UploadProps } from "antd";
import { BulkTransactions } from "../constants/endpoints";
import langText from "../lang/i18n";
import LoadingSpinner from "./LoadingIndicator";
import Modal from "../components/Modal";
import animationData from "../assets/782-check-mark-success.json";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";

export default function ContactList() {
  const server = getCurrentServer();
  const { UserToken, UserData, chosenAcc } = useAppSelector(
    (state) => state.user
  );

  const { SelectedColor } = useAppSelector((state) => state.theme);
  const navigate = useNavigate();
  const [fileUploadResult, setFileUploadResult] = useState("");
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [success, setSuccess] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [completed, setCompleted] = useState(0);
  const [incompleteTransactions, setIncompleteTransactions] = useState<any>();

  const [totalAmount, setTotalAmount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const { Dragger } = Upload;

  const customRequest = async ({ file, onSuccess, onError }: any) => {
    try {
      setUploadedFile(file);
      onSuccess("", file);
    } catch (error) {
      // Handle error
      console.error("Upload error:", error);
      onError(error);
    }
  };

  const makeBatchTransfer = async () => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("transactionsFile", uploadedFile);
      formData.append("totalAmount", totalAmount);
      formData.append("senderAccount", JSON.stringify(chosenAcc?.id));

      const response = await fetch(BulkTransactions(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + UserToken,
        },
        body: formData,
      });

      // Handle success
      const result = await response.json();

      if (response.status === 200) {
        setIncompleteTransactions(result.incompleteTransactions);
        setCompleted(result.completed);
        setSuccess(true);
      } else if (response.status === 422) {
        setErrorMsg(result.proccessingError);
      } else {
        setErrorMsg(result?.message);
      }
      setLoading(false);
    } catch (error) {
      // Handle error
      console.error("Upload error:", error);
    }
  };

  const dragProps: UploadProps = {
    customRequest: customRequest,
    maxCount: 1,
    multiple: false,
    onChange(info) {
      setErrorMsg("");
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        setFileUploadResult(`${info.file.name} u ngarkua me sukses!`);
      } else if (status === "error") {
        setFileUploadResult(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file) {
      setErrorMsg("");
      setUploadedFile(null);
    },
    onDrop(e) {},
  };

  const handleDownload = () => {
    // Define your CSV content
    const csvContent = `data:text/csv;charset=utf-8,id,recipientAccount,amount,recipientName,description`;

    // Encode the content to be URI-friendly
    const encodedUri = encodeURI(csvContent);

    // Create an anchor element and set the download attribute
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample.csv");

    // Programmatically click the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link after triggering the download
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        borderRadius: "25px",
        border: "1px #cccccc",
        margin: "20px",
        marginTop: "-30px",
        backgroundColor: "#FFF",
        padding: "60px",
      }}
    >
      <h2
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: "20px",
          color: "#44494B",
        }}
      >
        {langText("bulk_payments")}
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#44494b",
            marginBottom: "20px",
          }}
        >
          {langText("bulk_payments_desc")}{" "}
          <a style={{ marginTop: "20px" }} onClick={handleDownload}>
            {langText("download_example_csv")}{" "}
          </a>
          <FontAwesomeIcon icon={faFile} color={"#546BEA"} />
        </div>

        <Dragger {...dragProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-hint">{langText("upload_file_instr")}</p>
        </Dragger>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "10px 0px",
            color: "green",
          }}
        >
          {fileUploadResult}
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "20px 0px 10px 0",
        }}
      >
        <label
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: "16px",
            lineHeight: "20px",
            color: "#44494b",
            marginBottom: "10px",
          }}
        >
          {langText("csv_total_amount")}
        </label>
        <input
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            padding: "15px",
            border: "1px solid grey",
            fontSize: "18px",
            borderRadius: "10px",
          }}
          onChange={(e) => {
            setTotalAmount(e.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "10px 0px",
          color: "red",
        }}
      >
        {errorMsg}
      </div>
      <button
        disabled={loading || !!!uploadedFile || !!!totalAmount}
        onClick={makeBatchTransfer}
        className="transfer-btn"
        style={{
          opacity: loading || !!!uploadedFile || !!!totalAmount ? 0.5 : 1,
          margin: "auto",
        }}
      >
        {loading ? <LoadingSpinner /> : "Dërgo"}
      </button>

      <Modal
        isVisible={success}
        setIsVisible={(bool: any) => {
          setSuccess(bool);
          setSuccess(false);
          setTotalAmount("");
          setUploadedFile(null);
          setErrorMsg("");
          setFileUploadResult("");
        }}
        title={"Rezultati i transfertave ne grup"}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingBottom: "15px",
                marginBottom: "15px",
                borderBottom: "1px solid gray",
              }}
            >
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: animationData,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={26}
                width={26}
                style={{ margin: "0 10px 0 0" }}
              />
              {completed + " transaksione u kryen me sukses!"}
            </div>

            {incompleteTransactions?.map((incompleteTrans: any) => {
              return (
                <div>
                  <FontAwesomeIcon
                    bounce={true}
                    color="orange"
                    style={{ margin: "0 10px 0 0" }}
                    icon={faExclamationTriangle}
                    size={"lg"}
                  />
                  {"Gabim në transaksionin me ID " +
                    parseInt(incompleteTrans.id) +
                    ": " +
                    incompleteTrans.error}
                </div>
              );
            })}

            <div
              style={{
                marginTop: "20px",
              }}
            >
              Ju lutem korrigjoni gabimet dhe riprovoni pagesat duke ngarkuar
              nje file csv qe permban vetem transaksionet e korrigjuara!
            </div>

            <Button
              // className="success-btn"
              style={{
                marginTop: "15px",
              }}
              onClick={() => {
                setSuccess(false);
                setTotalAmount("");
                setUploadedFile(null);
                setErrorMsg("");
                setFileUploadResult("");
              }}
            >
              {langText("close")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
