import { useState, useEffect, ChangeEvent } from "react";
import Utilities, { UtilityProps } from "../../constants/Utilities";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import "./UtilitiesScreen.css";
import langText from "../../lang/i18n";
import InvoiceItem from "../../components/InvoiceItem";
import UtilityPaidBill from "../../components/UtilityPaidBill";
import { useUtilityService } from "../../hooks/useUtilityService";
import { ServiceProps } from "../../constants/Utilities";
import {
  GetContractInvoice,
  GetInvoice,
  GetUKTInvoice,
  UtilityInvoices,
} from "../../constants/endpoints";
import { logOutClearDB } from "../../localDatabase/mainDB";
import { useAppSelector } from "../../hooks/reduxHooks";
import { logActivity } from "../../hooks/functions";
import { Contract, setContracts } from "../../redux/general-slice";
import LoadingSpinner from "../../components/LoadingIndicator";
import { useDispatch } from "react-redux";
import SearchScreen from "./SearchScreen";

export default function UtilitiesScreen() {
  const { UserToken, UserData, chosenAcc } = useAppSelector(
    (state) => state.user
  );
  const { contracts } = useAppSelector((state) => state.general);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeUtility, setActiveUtility] = useState<UtilityProps>();
  const [activeService, setActiveService] = useState<ServiceProps>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("+355 6");

  const [newContract, setNewContract] = useState<Map<string, string>>();
  const [value, setValue] = useState(300);

  useEffect(() => {
    if (!UserToken) {
      logOutClearDB();
      navigate("/login");
      return;
    }
  }, []);

  useEffect(() => {
    setNewContract(new Map<string, string>());
  }, [activeService]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt(event.target.value));
  };

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMobileNumber(event.target.value);
  };

  let username = mobileNumber;

  if (username.startsWith("355")) {
    username = "+" + username;
  } else if (username.startsWith("06")) {
    username = "+355" + username.substring(1);
  }

  const [invoices, setInvoices] = useState<any[]>([]);
  const [paidBills, setPaidBills] = useState<any[]>([]);
  const {
    loading: contractsLoading,
    errorMsg,
    deleteContract,
    saveContract,
  } = useUtilityService(
    activeUtility?.operatorId ?? activeService?.operatorId!,
    activeService
  );

  useEffect(() => {
    getPaidBills();
  }, []);

  useEffect(() => {
    getAllBills(contracts);
    getPaidBills();
  }, [contracts, activeUtility?.operatorId, activeService?.operatorId]);

  async function getAllBills(list: Contract[]) {
    logActivity(list);
    setLoading(true);
    if (list == null || list?.length === 0) {
      setLoading(false);
      setErrorMessage(
        !langText(
          "bill_id_desc_" +
            (activeService?.name.includes("UK")
              ? "uk"
              : activeService?.operatorId ?? activeUtility?.operatorId)
        ).startsWith("[missing")
          ? langText(
              "bill_id_desc_" +
                (activeService?.name.includes("UK")
                  ? "uk"
                  : activeService?.operatorId ?? activeUtility?.operatorId)
            )
          : langText("bill_id_desc")
      );
      return;
    }
    let newList: any[] = [];
    await Promise.all(
      list.map(async (obj, index) => {
        // let contract = Object.keys(obj)[0]; // obj["accountNo_phone_number"];
        // let contract = obj[Object.keys(obj)[0]]; // obj["Numri i kontrates"];
        let billList = await getBills(obj);
        if (billList?.length > 0) {
          newList.push(...[getBillHeader(obj), ...billList]);
        }
      })
    );

    logActivity("new bills", newList);
    setInvoices(newList);
    setLoading(false);
  }

  function getBillHeader(object: Contract | any) {
    return !!object.vlera
      ? {
          value: object.vlera,
          onPress: () => {
            deleteContract(object);
          },
        }
      : object;
  }

  async function getBills(contract: Contract) {
    try {
      let reqBody = requestBody(contract);
      let response = await fetch(
        activeService?.operatorId === "22"
          ? GetUKTInvoice()
          : activeUtility?.short_name === "Vodafone"
          ? GetInvoice()
          : GetContractInvoice(),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
          body: reqBody,
        }
      );
      setLoading(false);

      let res = await response
        .json()
        .catch((e) => logActivity("response json error", e));
      if (response.status === 200) {
        if (
          activeUtility?.short_name === "UK" &&
          res.invoicesByContractNo?.code !== 0
        ) {
          return [{ message: langText("no_bills"), error: false }];
        } else if (Array.isArray(res)) {
          let tempList = res.filter((item) => !item?.paguar);
          res = { Invoices: tempList };
        } else if (!res.Invoices) {
          let osheeStatusCode = Array.isArray(res.invoices)
            ? res.invoices[0]?.statusCode
            : res.invoice?.statusCode;
          if (
            res.invoices === "No data to Display" ||
            osheeStatusCode === "39" ||
            osheeStatusCode === "22" ||
            (typeof res == "string" &&
              res.includes("There is no unpaid debt for subscriber"))
          ) {
            setErrorMessage(langText("no_bills"));
            return [{ message: langText("no_bills"), error: false }];
          }
          if (!!osheeStatusCode || res?.invoicesByContractNo?.code == "8") {
            setErrorMessage(langText("wrong_inputs_bills"));
            return [{ message: langText("wrong_inputs_bills"), error: true }];
          }
          res.Invoices = res.invoicesByContractNo?.invoices ??
            res.fines ??
            res.invoices ??
            res.Products ?? [res.invoice];
        }

        res.Invoices.map((item: any) => {
          item.contract = contract;
        });
        if (res.Invoices.length === 0)
          return [{ message: langText("no_bills"), error: false }];
        if (res.Invoices.length === 1 && res.Invoices[0].error_code === "2")
          return [
            {
              message: langText("no_bills"),
              error: false,
            },
          ];
        return res.Invoices;
      } else if (response.status === 400) {
        if (
          typeof res == "string" &&
          res.includes("There is no unpaid debt for subscriber")
        ) {
          setErrorMessage(langText("no_bills"));
          return [{ message: langText("no_bills"), error: false }];
        }
        setErrorMessage(langText("wrong_inputs_bills"));
        return [{ message: langText("wrong_inputs_bills"), error: true }];
      } else if (response.status === 204) {
        setErrorMessage(langText("contract_not_found"));
        return [{ message: langText("contract_not_found"), error: true }];
      } else {
        setErrorMessage(langText("connection_error"));
        return [{ message: langText("connection_error"), error: true }];
      }
    } catch (e: any) {
      let err = Error(e);
      logActivity("err", err.message);
      setLoading(false);
      return [{ message: langText("connection_error"), error: true }];
    }
  }

  function requestBody(contract: Contract | any) {
    if ((activeService?.operatorId ?? activeUtility?.operatorId) == "1") {
      let vodafoneContract = contract?.vlera;
      let accountNo_phone_number = (
        vodafoneContract["accountNo_phone_number"] ?? vodafoneContract
      ).trim();
      let phoneNumber =
        accountNo_phone_number.startsWith("+355") ||
        accountNo_phone_number.startsWith("355")
          ? accountNo_phone_number.startsWith("+")
            ? accountNo_phone_number.substring(1)
            : accountNo_phone_number
          : accountNo_phone_number.startsWith("6") &&
            accountNo_phone_number.length == 9
          ? "355" + accountNo_phone_number
          : accountNo_phone_number.startsWith("0") &&
            accountNo_phone_number.length == 10
          ? "355" + accountNo_phone_number.substring(1)
          : null;
      return JSON.stringify({
        operatorId: activeService?.operatorId ?? activeUtility?.operatorId,
        phone: phoneNumber ?? "",
        customerName: isNaN(vodafoneContract["customerName"])
          ? vodafoneContract["customerName"]?.trim()
          : "",
        accountNo: !phoneNumber ? accountNo_phone_number : "",
        currency: activeService?.currency,
        customerId: UserData!.id,
        token: UserToken,
      });
    }
    if (activeService?.operatorId == "22")
      return JSON.stringify(
        contract?.vlera?.split("-").length > 2
          ? {
              invoiceNumber: contract?.vlera?.trim(),
              operatorId: "22",
            }
          : {
              contractNumber: contract?.vlera?.trim(),
              operatorId: "22",
            }
      );

    if ((activeService?.operatorId ?? activeUtility?.operatorId) == "23")
      return JSON.stringify({
        operatorId: activeService?.operatorId ?? activeUtility?.operatorId,
        platesNo: contract?.vlera?.replace(/\ /g, ""),
        customerId: UserData!.id,
      });

    if ((activeService?.operatorId ?? activeUtility?.operatorId) == "24")
      return JSON.stringify(
        !isNaN(contract?.vlera?.trim())
          ? {
              operatorId: "24",
              documentNo: contract?.vlera?.trim(),
              agreement: "F",
            }
          : {
              operatorId: "24",
              customerNo: contract?.vlera?.trim(),
              agreement: "F",
            }
      );

    if ((activeService?.operatorId ?? activeUtility?.operatorId) == "25")
      return JSON.stringify({
        operatorId: "25",
        customerNo: contract?.vlera?.accountNo_phone_number?.trim(),
      });

    if ((activeService?.operatorId ?? activeUtility?.operatorId) == "26")
      return JSON.stringify({
        nid: contract?.vlera?.trim(),
      });

    return JSON.stringify({
      operatorId: activeService?.operatorId ?? activeUtility?.operatorId,
      contractNo: contract?.vlera?.trim(),
      customerId: UserData!.id,
    });
  }

  function getPaidBills() {
    try {
      setPaidBills([]);

      fetch(
        UtilityInvoices(
          chosenAcc?.id +
            "/" +
            (activeService?.operatorId ?? activeUtility?.operatorId)
        ),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + UserToken,
          },
        }
      ).then((response) => {
        logActivity(
          UtilityInvoices(
            chosenAcc?.id +
              "/" +
              (activeService?.operatorId ?? activeUtility?.operatorId)
          ),
          response.status
        );
        if (response.status === 200) {
          response.json().then((res) => {
            if (Array.isArray(res.Invoices)) {
              setPaidBills(res.Invoices);
            }
          });
        }
      });
    } catch (e) {
      logActivity(e);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Sidebar />
      <div
        style={{ width: "100%", height: "100%", marginLeft: "7rem" }}
        className="page"
      >
        <div className="title" style={{ margin: 20 }}>
          <Header title={""} />
          <p
            style={{
              margin: 10,
              marginLeft: 25,
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "40px",
              lineHeight: "60px",
              color: "#414141",
            }}
          >
            Faturat dhe Shërbime
          </p>
          <h5
            className="header2"
            style={{
              margin: 10,
              marginLeft: 25,
              fontSize: "20px",
              left: "0px",
              top: "60px",
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 100,
              color: "#414141",
            }}
          >
            Fillimisht zgjidh një utilitet, më pas shërbimin dhe paguaj faturat.
          </h5>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            marginLeft: 20,
            marginTop: 40,
          }}
        >
          {Utilities.map((utility) => (
            <div>
              <div
                key={utility.operatorId ?? utility.short_name}
                style={{
                  width: "120px",
                  height: "120px",
                  margin: "10px",
                  marginRight: "25px",
                  marginLeft: "25px",
                  borderRadius: "20px",
                  boxShadow:
                    activeUtility?.id === utility.id
                      ? "0 4px 8px 0 rgba(84, 107, 234, 0.2)"
                      : "0 0px 0px 0 rgba(0, 0, 0, 0.1)",
                  backgroundColor: "white",
                  padding: "10px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                  border:
                    activeUtility?.id === utility.id
                      ? "1px solid rgba(84, 107, 234, 0.8)"
                      : "1px solid rgba(0, 0, 0, 0.2)",

                  fontWeight: activeUtility?.id === utility.id ? "600" : "400",
                }}
                onClick={() => {
                  dispatch(setContracts([]));
                  setInvoices([]);

                  setActiveUtility(utility);
                  setActiveService(utility.services[0]);
                }}
              >
                <img
                  src={utility.logo() as string}
                  alt={utility.short_name}
                  style={{ height: "100%" }}
                />
              </div>
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  color:
                    activeUtility?.id === utility.id ? "#546BEA" : "#A5A5A5",
                  fontWeight: activeUtility?.id === utility.id ? "600" : "400",
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                }}
              >
                {langText(utility.short_name)}
              </span>
            </div>
          ))}
        </div>
        {/* active services */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginLeft: 50,
          }}
        >
          {activeUtility?.services.map((service, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  dispatch(setContracts([]));
                  setInvoices([]);
                  setActiveService(service);
                }}
                style={{
                  margin: "30px",
                  marginRight: "60px",
                  marginLeft: "-8px",
                  color:
                    service.id == activeService?.id ? "#546BEA" : "#626262",
                  fontSize: 22,
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                {langText(service.name)}
              </div>
            );
          })}
        </div>

        <div
          style={{
            marginLeft: 40,
          }}
        >
          {activeService?.type === "invoice" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                cursor: "cursor",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  cursor: "cursor",
                }}
              >
                <div
                  style={{
                    borderRadius: 10,
                    backgroundColor: `rgba(0, 0, 0, 0.00)`,
                    marginBottom: 20,
                    width: 400,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {!!activeService &&
                      !!activeService.fields &&
                      activeService.fields.map((field: any, index: number) => {
                        return (
                          <div>
                            <label
                              style={{
                                textAlign: "center",
                                marginRight: 20,
                                color: "#414141",
                              }}
                              htmlFor={field.label}
                            >
                              {langText(field.label)}
                            </label>
                            <input
                              style={{
                                width: "323px",
                                height: "51px",
                                padding: "0.5rem",
                                borderRadius: "5px",
                                border: "1px solid gray",
                                fontSize: "16px",
                                marginRight: "20px",
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "150%",
                                paddingLeft: "5%",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.0275em",
                                fontFeatureSettings: `'tnum' on, 'lnum' on, 'ss01' on`,
                                color: "#9696A0",
                              }}
                              type="text"
                              id={field.label}
                              value={newContract?.get(field.label) ?? ""}
                              onChange={(e) => {
                                let newMap = new Map<string, string>();
                                newContract?.forEach((value, key) => {
                                  newMap.set(key, value);
                                });
                                newMap.set(field.label, e.target.value);
                                setNewContract(newMap);
                              }}
                              maxLength={15}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <div>
                    <button
                      style={{
                        marginTop: 20,
                        width: "336px",
                        backgroundColor: "#546BEA",
                        padding: "1rem",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        opacity: !newContract ? 0.5 : 1,
                      }}
                      onClick={() => saveContract(newContract! as any)}
                    >
                      {langText(
                        "add_new_" +
                          (activeService?.name.includes("UK")
                            ? "uk"
                            : activeService?.operatorId ??
                              activeUtility?.operatorId)
                      )}
                    </button>

                    {!!errorMsg && (
                      <div style={{ color: "red", textAlign: "center" }}>
                        {errorMsg}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      flexDirection: "column",
                    }}
                  >
                    {loading || contractsLoading ? (
                      <LoadingSpinner />
                    ) : (
                      invoices.map((invoice) => {
                        return (
                          <InvoiceItem
                            bill={invoice}
                            service={activeUtility!.services[0]}
                            utility={activeUtility}
                          />
                        );
                      })
                    )}
                  </div>

                  {paidBills.length > 0 && (
                    <h5
                      className="header2"
                      style={{
                        margin: 10,
                        marginLeft: 25,
                        fontSize: "20px",
                        left: "0px",
                        top: "60px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 100,
                        color: "#414141",
                        borderBottom: "1px solid #EFEFEF",
                      }}
                    >
                      {langText("last_transactions")}
                    </h5>
                  )}
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      flexDirection: "column",
                      marginBottom: "40px",
                    }}
                  >
                    {paidBills.map((bill: any, index) => {
                      return <UtilityPaidBill item={bill} key={index} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeService?.type === "topUp" && (
            <div>
              <div
                style={{
                  marginLeft: "40px",
                  marginBottom: "40px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "48px 37px",
                  gap: "10px",
                  width: "742px",
                  height: "365px",
                  left: "38px",
                  top: "576px",
                  background: "#FFFFFF",
                  border: "1px solid rgba(84, 107, 234, 0.85)",
                  borderRadius: "25px",
                }}
              >
                <div>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "20px",
                      marginBottom: " 20px",
                      marginTop: "8px",
                    }}
                  >
                    Vendosni të dhënat tuaja për të rimbushur.
                  </p>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: "20px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    >
                      Numri i telefonit
                    </p>
                    <input
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "12px 16px",
                        gap: "10px",
                        width: "300px",
                        height: "42px",
                        border: "1.4px solid #C5C5C5",
                        borderRadius: "4px",
                      }}
                      type="tel"
                      id="mobileNumber"
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                      maxLength={14}
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    >
                      Shuma
                    </p>

                    <input
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "12px 16px",
                        gap: "10px",
                        width: "300px",
                        height: "42px",
                        border: "1.4px solid #C5C5C5",
                        borderRadius: "4px",
                      }}
                      type="number"
                      min="300"
                      max="3200"
                      value={value}
                      onChange={handleChange}
                      step={100}
                      maxLength={4}
                    />
                  </div>
                </div>
                <button
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px 145px",
                    gap: "10px",
                    width: "668px",
                    height: "66px",
                    background: "#546BEA",
                    borderRadius: "10px",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: "20px",
                    borderColor: "transparent",
                  }}
                  onClick={() => {
                    navigate("/confirm-payment", {
                      state: {
                        friendInfo: {
                          name: activeUtility?.short_name,
                          recipientCurrency: "ALL",
                          phoneNo: mobileNumber.substring(
                            1,
                            mobileNumber.length
                          ),
                          operatorId: activeUtility?.operatorId,
                        },
                        amount: value,
                        message: activeUtility?.short_name + " top up",
                        fromQR: "",
                        goTo: "Home",
                        logo: activeUtility?.logo(),
                      },
                    });
                  }}
                >
                  Rimbush
                </button>
              </div>
            </div>
          )}

          {activeService?.type === "search" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                cursor: "cursor",
              }}
            >
              <SearchScreen service={activeService} provider={activeUtility} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
