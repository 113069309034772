import { Dispatch, SetStateAction, useMemo, useState } from "react";
import langText from "../lang/i18n";
import Modal from "./Modal";
import { AccountProps, setAccounts } from "../redux/user-slice";
import { useAppSelector } from "../hooks/reduxHooks";
import { formatMoney, handleCardNumber } from "../functions";
import "./AccountDetailsModal.css";
import { AccountUrl } from "../constants/endpoints";
import { useDispatch } from "react-redux";
import LoadingSpinner from "./LoadingIndicator";

type Props = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  account: AccountProps | null;
};
export default function AccountDetailsModal({
  isVisible,
  setIsVisible,
  account,
}: Props) {
  const BIC = "FINVALTR";
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch = useDispatch();

  const { allAccounts } = useAppSelector((state) => state.user);
  const { PersonId, UserToken } = useAppSelector((state) => state.user);
  const accountDetails = useMemo(
    () =>
      allAccounts.filter((a) => {
        return a.accountType.includes("CHECKING") && a.id === account?.id;
      })[0],
    [account, allAccounts]
  );

  async function changeAccount(accountId: number, newName?: string) {
    setLoading(true);
    const changeAccountEndpoint = AccountUrl(PersonId!) + "/" + accountId;
    const response = await fetch(changeAccountEndpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
      body: JSON.stringify({
        name: newName,
      }),
    });
    if (!response.ok) {
      setErrorMsg(langText("error"));
      setLoading(false);
      return;
    }
    await response.json();
    getAllAccounts();
  }

  async function getAllAccounts() {
    const response = await fetch(AccountUrl(PersonId!), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    });
    if (!response.ok) {
      setErrorMsg(langText("error"));
      setLoading(false);
      return;
    }
    const data: AccountProps[] = await response.json();

    const order: Record<string, number> = {
      CHECKING_PERSONAL: 1,
      CHECKING_BUSINESS: 2,
      SAVINGS_PERSONAL: 3,
    };
    data.sort((a: any, b: any) => {
      return order[a.accountType] - order[b.accountType];
    });

    const filteredOrder: Record<string, number> = {
      ALL: 1,
      EUR: 2,
    };

    data.sort((a: any, b: any) => {
      return filteredOrder[a.currency] - filteredOrder[b.currency];
    });

    dispatch(setAccounts(data));
    setLoading(false);
  }

  return (
    accountDetails && (
      <Modal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        title={langText("account_details")}
      >
        <div style={{ margin: "0.5em" }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              <div className="account-info">
                <label>{langText("account_name") + ": "}</label>
                {accountDetails.accountHolder.name}
              </div>
              <div className="account-info">
                <label>{langText("balance") + ": "}</label>
                {formatMoney(accountDetails.balance.amount.value) +
                  " " +
                  accountDetails.currency}
              </div>
              {accountDetails.iban ? (
                <div>
                  <div className="account-info">
                    <label>{langText("account_id") + ": "}</label>
                    {accountDetails.balance.id}
                  </div>
                  <div className="account-info">
                    <label>{langText("iban") + ": "}</label>
                    {handleCardNumber(accountDetails.iban)}
                  </div>
                  <div className="account-info">
                    <label>{langText("bic_code") + ": "}</label>
                    {BIC}
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => {
                    changeAccount(accountDetails.id);
                  }}
                  className="generate-iban-btn"
                >
                  {langText("generate_iban")}
                </button>
              )}
            </div>
          )}
          <div style={{ padding: "0.5em", color: "red" }}>{errorMsg}</div>
        </div>
      </Modal>
    )
  );
}
