import "./AccessesAndRoles.css";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { default as CustomButton } from "../../components/Button";
import { useState } from "react";
import langText from "../../lang/i18n";
import { DownOutlined } from "@ant-design/icons";
import { FaTrash } from "react-icons/fa";

const AccountAccess = ({ account, updateAccountAccess }: any) => {
  const [accessTypeInput, setAccessTypeInput] = useState<string>("SELECT");
  const [customerMobile, setCustomerMobile] = useState<string>("");

  const handleCustomerMobileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCustomerMobile(event.target.value);
  };

  const handleAccessTypeClick: MenuProps["onClick"] = (event) => {
    setAccessTypeInput(event.key);
  };

  const items: MenuProps["items"] = [
    {
      label: langText("full_rights"),
      key: "FULL_RIGHTS",
    },
    { label: langText("read_rights"), key: "READ_RIGHTS" },
    { label: langText("reports"), key: "REPORTS" },
  ];

  const menuProps = {
    items,
    onClick: handleAccessTypeClick,
  };

  return (
    <div key={account.id} className="account">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div className="acc-info">
            <label>{langText("account_id")}:</label>
            <p> {account.id}</p>
          </div>
          <div className="acc-info">
            <label>{langText("account_holder")}:</label>
            <p> {account.accountHolder.name}</p>
          </div>
          <div className="acc-info">
            <label>{langText("account_name")}:</label>
            <p> {account.name}</p>
          </div>
          <div className="acc-info">
            <label>{langText("currency")}:</label>
            <p> {account.currency}</p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            width: "50%",
          }}
        >
          <div style={{ width: "100%", marginBottom: "0.5em" }}>
            {langText("give_access_to")}
          </div>
          <input
            id="phone-number-input"
            type="text"
            value={customerMobile}
            onChange={(e) => handleCustomerMobileChange(e)}
            style={{
              borderRadius: "5px",
              border: "1px solid #dbdbdb",
              width: "100%",
              height: "32px",
              marginBottom: "0.5em",
            }}
          />

          <div style={{ width: "100%", marginBottom: "0.5em" }}>
            {langText("choose_access_type")}
          </div>
          <Dropdown menu={menuProps}>
            <Button style={{ marginBottom: "1em" }}>
              <Space>
                {accessTypeInput}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
          <CustomButton
            onClick={() => {
              updateAccountAccess(
                account,
                accessTypeInput,
                "add",
                customerMobile
              );
            }}
          >
            {langText("add")}
          </CustomButton>
        </div>
      </div>
      {account.accountAccesss.length > 0 && (
        <div>
          <div
            style={{
              fontFamily: "Poppins",
              borderBottom: "2px solid #EDEDED",
              padding: "5px",
              margin: "1em 0em",
            }}
          >
            {langText("clients_with_access")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "left",
            }}
          >
            <table>
              <thead>
                <tr>
                  <td>{langText("client")}</td>
                  <td>{langText("access_type")}</td>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {account.accountAccesss.map((element: any) => {
                  return (
                    <tr id={element.id}>
                      <td>{element.customerMobile}</td>
                      <td>{element.accessRightType}</td>
                      <td>
                        {
                          <FaTrash
                            color="#a9262b"
                            onClick={() => {
                              updateAccountAccess(
                                account,
                                element.accessRightType,
                                "remove",
                                element.customerMobile
                              );
                            }}
                          />
                        }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountAccess;
