import React, { FC } from "react";
import LoadingSpinner from "./LoadingIndicator";
import { hexWithOpacity, logActivity } from "../hooks/functions";
import { useAppSelector } from "../hooks/reduxHooks";

export type ButtonProps = {
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  loading?: boolean;
  disabled?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  trailingIcon?: React.ReactNode;
  leadingIcon?: React.ReactNode;
  children?: any;
  onClick?: () => void;
};

type MandatoryProps = {};

const Button: FC<ButtonProps & MandatoryProps> = ({
  onClick,
  style,
  textStyle,
  loading,
  disabled,
  secondary,
  transparent,
  leadingIcon,
  trailingIcon,
  children,
}) => {
  const { SelectedColor, Theme } = useAppSelector((state) => state.theme);

  return (
    <button
      style={{
        borderRadius: 10,
        width: "100%",
        padding: 10,
        backgroundColor: transparent
          ? "transparent"
          : secondary
          ? hexWithOpacity(SelectedColor, 0.3)
          : SelectedColor,
        borderWidth: 1,
        borderColor: secondary ? `rgba(0,0,0,0)` : SelectedColor,
        ...style,
      }}
      disabled={disabled || loading}
      onClick={() => {
        onClick ? onClick() : logActivity("No click action");
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!!leadingIcon && (
            <div
              style={{
                marginRight: 10,
              }}
            >
              {leadingIcon}
            </div>
          )}
          <div
            style={{
              textAlign: "center",
              fontSize: 17,
              color: transparent || secondary ? SelectedColor : "#FFF",
              ...textStyle,
            }}
          >
            {children}
          </div>
          {!!trailingIcon && (
            <div
              style={{
                marginLeft: 10,
              }}
            >
              {trailingIcon}
            </div>
          )}
        </div>
      )}
    </button>
  );
};

export default Button;
