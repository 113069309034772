import { NumericFormat } from "react-number-format";
import { getDBChosenAcc, getUserToken } from "../localDatabase/mainDB";
import { GetBooking } from "../constants/endpoints";
import { useNavigate } from "react-router-dom";
import DateText from "./DateText";
import langText from "../lang/i18n";
import { logActivity } from "../hooks/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

function UtilityPaidBill({ item }: { item: any }) {
  const navigate = useNavigate();
  const UserToken = getUserToken();
  const accountData = getDBChosenAcc();
  const openTransactionDetails = (bookingId: number) => {
    fetch(GetBooking(bookingId.toString()), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + UserToken,
      },
    }).then((res) => {
      logActivity(GetBooking(bookingId.toString()), res.status);
      if (!res.ok) return;
      res.json().then((res) => {
        navigate("/transaction-details", {
          state: {
            booking: res,
          },
        });
      });
    });
  };

  return (
    <div
      key={item.data}
      style={{
        borderRadius: "25px",
        backgroundColor: "#ffffff",
        border: "1px solid #546BEA",
        margin: "2em 0em",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "30px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flex: "1",
            justifyContent: "space-between",
          }}
        >
          <NumericFormat
            value={item.amount}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => {
              return (
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "550",
                    fontSize: "36px",
                    lineHeight: "54px",
                    marginLeft: "20px",
                    color: "#546BEA",
                  }}
                >
                  {value + " " + langText(accountData?.currency)}
                </span>
              );
            }}
          />
          <div
            onClick={() => {
              openTransactionDetails(item.sender_booking);
            }}
          >
            <FontAwesomeIcon size="xl" color={"#546BEA"} icon={faInfoCircle} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flex: "1",
          }}
        >
          <div
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "5px",
              marginRight: "15px",
              boxShadow: `2px 2px 5px #546BEA`,
              backgroundColor: "#FFF",
            }}
          >
            <img
              src={item?.logo ?? "https://friends.on.al/offers/host-logo.png"}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontFamily: "'Poppins'",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "27px",
                color: "#858585",
                marginLeft: "20px",
                marginTop: "-15px",
              }}
            >
              {item.operator_name}
            </div>

            <span
              style={{
                margin: "0px 20px",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  fontFamily: "'Poppins'",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "27px",
                  color: "#858585",
                }}
              >
                <DateText date={item.timestamp} />
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UtilityPaidBill;
